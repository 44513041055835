<!-- Z2I81GIRE-162 -->
<template>
  <div>
    <PageLoading v-if="requsterProcesing" />
      <div class="modal-retrieve" :class="{'hide-content-on-loading': this.requsterProcesing}">
        <!-- List registration -->
        <div class="modal-retrieve__registrations-list">
          <label v-if="getSelectedDetailed.length > 1">{{ $t('removeHCModal.sub_title_1') }}</label>
          <label v-if="getSelectedDetailed.length <= 1">{{ $t('removeHCModal.sub_title_2') }}</label>
        </div>
        <div class="is-gapless retrive-multi__registrations">
            <div
              v-for="heli in getSelectedDetailed"
              :key="heli.registration"
            >{{cleanData(heli.type)}}/{{heli.serial}} : {{heli.registration}}
          </div>
          </div>
      </div>
      <!-- Buttons -->
      <div class="columns is-centered modal-retrieve__buttons-wrapper">
        <a @click="closeModal" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
        <a class="button btn validationBtn" @click="removeHC()">{{ $t('global.button_remove_hc') }}</a>
      </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '../../mixins/utils'
import PageLoading from '../../views/PageLoading'

export default {
  components: {
    PageLoading
  },
  name: 'ModalRetrieveMulti',
  mixins: [utils],
  data () {
    return {
      requsterProcesing: false,
      modal: false
    }
  },
  computed: {
    ...mapGetters('fleetStore', ['getSelectedDetailed'])
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    removeHC () {
      this.requsterProcesing = true
      let formData = new FormData()
      let selectedHelicopers = this.getSelectedDetailed.map(h => {
        return {
          registration: h.registration,
          serial: h.serial,
          type: h.type
        }
      })
      formData.append(
        'selected_helicopters',
        JSON.stringify(selectedHelicopers)
      )

      this.$requester
        .post('/remove', formData, {
          responseType: 'blob'
        })
        .then(response => {
          this.$router.go()
        })
        .catch(error => {
          if (error.response) {            
            let errMsg = this.$t('errorMsg.err')
            this.$emit('responseError', `${errMsg}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
.retrive-multi__registrations {
  margin: 20px 0 !important;
  div {
    font-size: 14px;
  }
}
.validationBtn{
  color: white !important;
}
</style>
