<template>
  <v-date-picker
    first-day-of-week="1"
    class="date-range-picker"
    :value="value"
    @input="onInput"
    color="light-blue"
    event-color="light-blue date-in-range"
    :events="selectedRange"
    :allowed-dates="allowedDates"
    no-title
    multiple
  >
    <slot></slot>
  </v-date-picker>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    allowedDates: Function
  },
  data () {
    return {
      dates: []
    }
  },
  created () {
    this.dates = this.value
  },
  watch: {
    value (val) {
      this.dates = val
    }
  },
  methods: {
    diff (a, b) {
      return a.filter(v => !b.includes(v))
    },
    onInput (val) {
      let newRange
      if (val.length > 2) {
        let newDate = this.diff(val, this.dates)[0]
        let currentRange = this.dates.sort()
        newRange = [currentRange[0], newDate]
      } else {
        newRange = val
      }
      this.dates = newRange.sort()
      this.$emit('input', this.dates)
    },
    selectedRange (date) {
      if (this.dates.length === 2) {
        return this.$moment(date).isBetween(...this.dates)
      }
    }
  }
}
</script>

<style lang="scss">
.date-range-picker {
  .v-date-picker-table__events {
    height: 32px;
    width: 32px;
    z-index: 0;
    left: -2px;
    top: -1px;
  }
  .date-in-range {
    height: 32px;
    width: 32px;
  }
  .v-btn__content {
    z-index: 1;
  }
  .v-date-picker-table__events > div {
    border-radius: 0px;
  }
  .v-btn--floating,
  .v-btn--icon:before {
    border-radius: 0px !important;
  }
}
</style>
