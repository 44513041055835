<template>
  <div class="app-wrapper">
    <AlertBanner />
    <TheHeader :headerTop="headerTop"/>
    <TheSidebar :headerTop="headerTop"/>

    <main class="main-container" :style="{ top: subHeaderTop }">
      <slot/>
    </main>
  </div>
</template>

<script>
import TheHeader from '../components/navigations/TheHeader'
import TheSidebar from '../components/navigations/TheSidebar'
import AlertBanner from '../components/AlertBanner'
import PageLoading from '../views/PageLoading'

export default {
  name: 'NavLayout',
  components: {
    TheHeader,
    TheSidebar,
    AlertBanner,
    PageLoading,
  },
  data() {
    return {
      headerTop: '0px',
      subHeaderTop: '60px',
      intervalId: null,
    }
  },
  created() {
    this.checkCardVisibility();

    this.$store.watch(
      (state) => state.isCardVisible,
      (newValue, oldValue) => {
        if (newValue !== oldValue && newValue != "volontaryHide") {
          this.checkCardVisibility(); 
        }
      }
    );

  },
  destroyed() {
    clearInterval(this.intervalId); // Clean up interval on component destruction
  },
  methods: {
    checkCardVisibility() {
  let retryCount = 0;
  const maxRetries = 3;

  const makeRequest = () => {
    console.log("Checking card visibility");
    this.$requester
      .get('/bannerVisibility')
      .then(response => {
        const newVisibility = response.data.status;
        const newVisibilityBoolean = newVisibility === 1;
        this.$store.commit('showCard', newVisibilityBoolean);
        this.$store.commit('setBannerText', response.data.msg || '');
      })
      .catch(error => {
        console.error('API call failed', error);
        if (error.response && error.response.status === 401) {
          retryCount++;
          if (retryCount <= maxRetries) {
            console.error('Unauthorized access. Retrying after 5 seconds...');
            setTimeout(() => {
              makeRequest();
            }, 5000);
          } else {
            console.error('Max retries reached. Unable to authorize.');
          }
        }
      })
      .finally(() => {
        if (this.$store.state.isProcessing) {
          this.$store.commit('toggleProcessing');
        }
      });
  };

  makeRequest();
}
  }
}
</script>
