export const HMI_EC225_FILES = [
  { name: 'CF',
    types: ['AMC & MFD', 'DMAU', 'FMS', 'WACS']
  },
  { name: 'AD',
    types: ['FMS', 'MFD']
  },
  { name: 'S/W',
    types: ['AMC', 'MFD', 'DMAU']
  }
  ]
  