<template>

  <tr v-if="!file.test" :class="{'is-hidden' : file.delete_confirmed}">
    <td id="row"  class="center-header">
      <div class="checkbox-holder" v-if="file.usr_file_type !== 'CERTIF'">
        <input 
          type="checkbox" 
          :id="'checkbox-' + helico.serial + '-' + file.file_name" 
          :checked="file.selected" 
          @change="selectFile(helico, file)"
        >
        <label 
          :for="'checkbox-' + helico.serial + '-' + file.file_name"
        ></label>
      </div>
    </td>
    <td id="row" class="truncate"  :title=lastConnectionFormatedDateAndTime()>{{ truncateText(lastConnectionFormatedDateAndTime()) }}</td>
    <td id="row" class="truncate"  :title="file.initial_file_name">{{ truncateText(file.initial_file_name ? file.initial_file_name : 'n/a') }}</td>
    <td id="row" class="truncate"  :title="file.file_name">{{ truncateText(file.file_name) }}</td>
    <td id="row" class="center" >{{ file.usr_file_type ? file.usr_file_type : 'n/a'}} </td>
    <td id="row" class="center" >{{ file.target }}</td>
    <td id="row" class="status"  :class="{'failed' : file.transfer_status === 'failed'}">{{ $t('transfer.' + file.transfer_status) }}</td>
    <td id="row" class="center"  v-if="file.usr_file_type !== 'CERTIF'">{{ file.rejected ? file.rejected + '/3' : '0/3' }}</td>

  </tr>
  
  </template>
  
  
  
  <script>
  var offsetUTC = -(new Date().getTimezoneOffset()/60) > 0 ? '+' + -(new Date().getTimezoneOffset()/60) : -(new Date().getTimezoneOffset()/60)
  export default {
    props: {
      file: {
        type: Object,
        required: true
      },
      helico: {
        type: Object,
        required: true
      },
      selectAll: {
        type: Boolean,
        required: true
      },
    },
    methods: {
      selectFile(helico, file) {
        this.$emit('file-selected', file, helico);
      },
      truncateText(text) {
        const maxLength = 10;
        if (text && text.length > maxLength) {
          return text.substring(0, maxLength) + '...'; // Truncate text
        }
        return text;
      },
      checkError (time) {
        if(time.includes('Invalid')){
          return 'n/a'
        }
        else return time.toString()
      },
      lastConnectionFormatedDateAndTime () {
        let time = this.file.upload_date;
        if (!time) return 'n/a'
  
        let lTime = this.$moment(time + ' GMT').format(
          'D MMM YYYY HH:mm'
        ) + ' UTC' + offsetUTC
  
        return this.checkError(lTime)
      },
    },
    mounted() {
      this.$parent.$on('select-all', this.selectAll);
    },
    watch: {
      selectAll: function(newValue) {
        this.file.selected = newValue;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  tr {
    width: 100%;
    th,
    td {

      min-width: 130px;
      padding: 12px 15px;
      font-size: 14px;
      border: none;
      &:last-child {
        text-align: center;
      }
    }
    td.center-header {
      min-width: 50px;
    }
    th {
      color: #8e9ba4;
      &:last-child {
      }
    }
    td {
      color: #00205b;
      font-weight: 400;
      &.status {
        font-weight: 600;
        text-align: center;
        &.failed {
          color: #e3002b;
        }
      }
      .truncate {
        max-width: 100px; /* Adjust as needed */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span.abort {
        background-color: #e3002b;
        border: none;
        border-radius: 290486px;
        cursor: pointer;
        pointer-events: auto;
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 0;
        height: 20px;
        max-height: 20px;
        max-width: 20px;
        min-height: 20px;
        min-width: 20px;
        outline: none;
        position: relative;
        vertical-align: top;
        width: 20px;
        &:before,
        &:after {
          background-color: white;
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
          transform-origin: center center;
        }
        &:before {
          height: 2px;
          width: 50%;
        }
        &:after {
          height: 50%;
          width: 2px;
        }
      }
      span.trash {
        position: relative;
        cursor: pointer;
      }
      .trash-holder {
        display: flex;
        justify-content: center;
        align-self: center;
        img {
          height: 20px;
        }
      }
    }
  }

.checkbox-holder label::after {
  line-height: 0px;
}
  </style>