<template>
  <div class="app-modal-upload-body">
    <PageLoading v-if="waiting_upload" :message="$t('uploadModal.waiting')"/>
    <div v-else>
      <div v-if="helicopter" class="columns app-modal-upload-body_title">
        <label class="column is-12 has-text-centered">
          {{ $t('uploadModal.sub_title') }}
          <span>{{ helicopter.registration }}</span>
        </label>
      </div>
      <div v-else class="app-modal-upload-body_multi-title">
        <div class="columns app-modal-upload-body_title">
          <label class="column is-12 has-text-centered">{{ $t('uploadModal.sub_title') }}</label>
        </div>
        <div class="columns app-modal-upload-body_title is-multiline is-centered is-vcentered is-mobile">
          <div v-for="hc in getSelectedDetailed" :key="hc.registration" class="column is-4 has-text-centered hc-reg">{{
            hc.registration }}</div>
        </div>
      </div>
      <div class="app-modal-upload-body_content">
        <div class="columns">
          <label class="column is-5">{{ $t('uploadModal.hmi_1') }}</label>
          <!-- HMI 1-->
          <template v-if="helicopter">
            <span
              v-if="helicopter.type !== 'AS350_B3' && helicopter.type !== 'EC130_T2' && helicopter.type !== 'EC225_LP'"
              class="column is-7">
              <multiselect v-model="HMIvalue1" track-by="name" label="name"
                :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileTypes" :searchable="false"
                :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
            </span>
            <span v-if="helicopter.type === 'AS350_B3' || helicopter.type === 'EC130_T2'" class="column is-7">
              <multiselect v-model="HMIvalue1" track-by="name" label="name"
                :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileMiniWacsTypes" :searchable="false"
                :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
            </span>
            <span v-if="helicopter.type === 'EC225_LP'" class="column is-7">
              <multiselect v-model="HMIvalue1" track-by="name" label="name"
                :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileEC225Types" :searchable="false"
                :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
            </span>
          </template>
          <template v-else>
            <span
              v-if="getSelectedDetailed[0].type !== 'AS350_B3' && getSelectedDetailed[0].type !== 'EC130_T2' && getSelectedDetailed[0].type !== 'EC225_LP'"
              class="column is-7">
              <multiselect v-model="HMIvalue1" track-by="name" label="name"
                :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileTypes" :searchable="false"
                :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
            </span>
            <span v-if="getSelectedDetailed[0].type === 'AS350_B3' || getSelectedDetailed[0].type === 'EC130_T2'"
              class="column is-7">
              <multiselect v-model="HMIvalue1" track-by="name" label="name"
                :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileMiniWacsTypes" :searchable="false"
                :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
            </span>
            <span v-if="getSelectedDetailed[0].type === 'EC225_LP'" class="column is-7">
              <multiselect v-model="HMIvalue1" track-by="name" label="name"
                :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileEC225Types" :searchable="false"
                :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
            </span>
          </template>
        </div>
        <!-- HMI 2-->
        <div class="columns">
          <label class="column is-5">{{ $t('uploadModal.hmi_2') }}</label>
          <span class="column is-7">
            <Multiselect v-if="HMIvalue1" v-model="HMIvalue2" :placeholder="this.$t('uploadModal.hmi_2_placeholder')"
              :options="HMIvalue1.types" :searchable="false" :reset-after="false" :show-labels="false"
              :allow-empty="false" @input="getPriority()" />
          </span>
        </div>
        <!-- HMI 3-->
        <div class="columns">
          <label class="column is-5">{{ $t('uploadModal.hmi_3') }}</label>
          <span class="column is-7">
            <Multiselect v-if="HMIvalue1" v-model="HMIvalue3" :placeholder="this.$t('uploadModal.hmi_3_placeholder')"
              :options="priority" :searchable="false" :reset-after="false" :show-labels="false" :allow-empty="false" />
          </span>
        </div>

        <!-- Upload files modal -->
        <div v-if="helicopter" class="columns">
          <label class="label label-btn column is-5">{{ $t('uploadModal.input_label') }}</label>
          <span class="column is-5">
            <label :for="helicopter.serial" class="inputStyle">{{ $t('config.browse') }}</label>
            <input :id="helicopter.serial" @change="getInputData()" type="file" :name="helicopter.serial" multiple />
            <span class="btn-status" :class="{ selectedColor: selectedColor }">{{ fileName }}</span>
          </span>
        </div>

        <!-- Upload data modal (no helicopter) -->
        <div v-else class="columns">
          <label class="label label-btn column is-5">{{ $t('uploadModal.input_label') }}</label>
          <span class="column is-5">
            <label for="hmiFiles" class="inputStyle">{{ $t('config.browse') }}</label>
            <input id="hmiFiles" @change="getInputData()" type="file" name="hmiFiles" multiple />
            <span class="btn-status" :class="{ selectedColor: selectedColor }">{{ fileName }}</span>
          </span>
        </div>

        <!-- Upload directory modal -->
        <div v-if="helicopter" class="columns">
          <label class="label label-btn column is-5">{{ $t('uploadModal.input_label_folder') }}</label>
          <span class="column is-5">
            <label :for="helicopter.serial + '-dir'" class="inputStyle">{{ $t('config.browse') }}</label>
            <input :id="helicopter.serial + '-dir'" @change="getDirData()" type="file" :name="helicopter.serial + '-dir'"
              webkitdirectory />
            <span class="btn-status" :class="{ selectedColor: selectedColor }">{{ directoryName }}</span>
          </span>
        </div>

        <!-- Upload data modal (no helicopter) -->
        <div v-else class="columns">
          <label class="label label-btn column is-5">{{ $t('uploadModal.input_label_folder') }}</label>
          <span class="column is-5">
            <label for="dirHmiFiles" class="inputStyle">{{ $t('config.browse') }}</label>
            <input id="dirHmiFiles" @change="getDirData()" type="file" name="dirHmiFiles" webkitdirectory />
            <span class="btn-status" :class="{ selectedColor: selectedColor }">{{ directoryName }}</span>
          </span>
        </div>

        <!-- Buttons -->
        <div class="columns is-centered buttons-wrapper">
          <a @click="closeModal" class="button btn column cancel-clean is-5">{{ $t('global.button_cancel') }}</a>
          <a id="upload-btn" :disabled="isUploadBtnDisabled" @click="uploadFiles()" class="button btn validationBtn column is-5">{{
            $t('global.button_upload') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import utils from '../../mixins/utils'
import { HMI_FILES } from '../../utils/constants/hmiFiles'
import { HMI_MINI_WACS_FILES } from '../../utils/constants/hmiMiniWacsFiles'
import { HMI_EC225_FILES } from '../../utils/constants/hmiEC225Files'
import PageLoading from '../../views/PageLoading'

export default {
  name: 'ModalUploadFiles',
  props: {
    helicopter: {
      type: Object,
      required: false
    }
  },
  components: {
    PageLoading
  },
  data() {
    return {
      fileTypes: HMI_FILES,
      fileMiniWacsTypes: HMI_MINI_WACS_FILES,
      fileEC225Types: HMI_EC225_FILES,
      HMIvalue1: '',
      HMIvalue2: '',
      HMIvalue3: '',
      inputStatus: [],
      uploadDisabled: true,
      selectedColor: false,
      fileName: this.$t('inputs.empty'),
      directoryName: this.$t('inputs.empty_dir'),
      priority: ['LOW', 'MEDIUM', 'HIGH'],
      waiting_upload: false
    }
  },
  computed: {
    ...mapGetters('fleetStore', ['getSelectedDetailed']),
    isUploadBtnDisabled() {
      return (
        this.HMIvalue1 === '' ||
        this.HMIvalue2 === '' ||
        this.HMIvalue3 === '' ||
        this.inputStatus.length === 0 ||
        this.uploadDisabled
      )
    },
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    getInputData() {
      let elementFileID = 'hmiFiles'
      let oldElementFileID = 'dirHmiFiles'
      if (this.helicopter) {
        elementFileID = this.helicopter.serial
        oldElementFileID = (this.helicopter.serial + '-dir')
      }
      let hmiFile = document.getElementById(elementFileID)
      document.getElementById(oldElementFileID).value = ''

      if (hmiFile.files[0]) {
        this.uploadDisabled = false
        this.inputStatus = hmiFile.files
        this.fileName = hmiFile.files[0].name
        this.directoryName = this.$t('inputs.empty_dir')
        setTimeout(() => {
          this.selectedColor = true
        }, 800)
      }
    },
    getDirData() {
      let elementFileID = 'dirHmiFiles'
      let oldElementFileID = 'hmiFiles'
      if (this.helicopter) {
        elementFileID = (this.helicopter.serial + '-dir')
        oldElementFileID = this.helicopter.serial
      }
      let hmiFile = document.getElementById(elementFileID)
      document.getElementById(oldElementFileID).value = ''

      if (hmiFile.files[0]) {
        this.uploadDisabled = false
        this.inputStatus = hmiFile.files
        this.directoryName = hmiFile.files[0].webkitRelativePath.split('/')[0]
        this.fileName = this.$t('inputs.empty')
        setTimeout(() => {
          this.selectedColor = true
        }, 800)
      }
    },
    getPriority() {
      let uploadMatrix = require('../../utils/matrix/upload_matrix.json')
      if (this.helicopter) {
        if (this.helicopter.type == 'AS350_B3' || this.helicopter.type == 'EC130_T2') {
          uploadMatrix = require('../../utils/matrix/upload_wacs_matrix.json')
        }
        if (this.helicopter.type == 'EC225_LP') {
          uploadMatrix = require('../../utils/matrix/upload_EC225_matrix.json')
        }
      } else {
        if (this.getSelectedDetailed[0].type == 'AS350_B3' || this.getSelectedDetailed[0].type == 'EC130_T2') {
          uploadMatrix = require('../../utils/matrix/upload_wacs_matrix.json')
        } 
        if (this.getSelectedDetailed[0].type == 'EC225_LP') {
          uploadMatrix = require('../../utils/matrix/upload_EC225_matrix.json')
        }
      }
      let priorityValue = uploadMatrix[this.HMIvalue1.name + '|' + this.HMIvalue2.replace(/\s/g, '')].priority
      this.HMIvalue3 = priorityValue
    },
    uploadFiles() {
      this.waiting_upload = true
      this.uploadDisabled = true
      let formData = new FormData()
      for (let i = 0; i < this.inputStatus.length; i++) {
        let file = this.inputStatus[i]
        formData.append('hmi_files[' + i + ']', file)
      }

      if (this.HMIvalue1.name) {
        formData.append('hmi_1', this.HMIvalue1.name)
      }
      if (this.HMIvalue2) {
        formData.append('hmi_2', this.HMIvalue2.replace(/\s/g, ''))
      }
      if (this.HMIvalue3) {
        formData.append('hmi_3', this.converter(this.HMIvalue3))
      }

      if (this.helicopter) {
        formData.append('registration', this.helicopter.registration)
        formData.append('serial', this.helicopter.serial)
        formData.append('type', this.helicopter.type)
      } else {
        let selectedHelicopers = this.getSelectedDetailed.map(h => {
          return {
            registration: h.registration,
            serial: h.serial,
            type: h.type
          }
        })
        formData.append(
          'selected_helicopters',
          JSON.stringify(selectedHelicopers)
        )
      }
      this.$requester
        .post('/upload', formData, {
          header: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {
          if (response) {
            //const dtrum = window.dtrum; 
            //dtrum.enterAction('Upload Event', { file_type: this.HMIvalue1.name, file_target: this.HMIvalue2 })
            this.waiting_upload = false
            let msg = this.$t('dialog.success_uploaded')
            this.$emit('responseSuccess', msg + " " + JSON.stringify(response.data))
          }
        })
        .catch(error => {
          if (error.response) {
            // if (error.response.status === 409) {
            // let errMsg = this.$t('errorMsg.err')
            let errMsg = error.response.data.msg
            this.$emit('responseError', `${errMsg}`)
            // }
          } else {
            // missing field
          }
        })
    },
    converter(priority) {
      switch (priority) {
        case '':
          break

        case 'LOW':
          return 'LOW'

        case 'MEDIUM':
          return 'MED'

        case 'HIGH':
          return 'HGH'
      }
    }
  }
}
</script>

<style lang="scss">
.app-modal-upload-body {
  $dark-blue: #002055;
  $pale-blue: #e6f3f6;
  $blue-lynch: #647491;
  $blue-midnight: #00205b;

  padding: 20px 70px;

  &_title {
    label {
      color: $dark-blue;
      font-size: 14px;
      font-weight: 400;
    }

    div {
      font-weight: 600;
    }
  }

  &_content {

    span,
    label {
      font-size: 14px;
      font-weight: 400;
      color: $dark-blue;

      .inputStyle {
        color: $blue-lynch;

        &:hover {
          color: #fff;
        }
      }
    }

    label {
      font-weight: 400;
      line-height: 2.5;
      color: $blue-lynch;
    }

    .btn-status {
      display: block;
      font-size: 12px;
      color: #647491;
    }

    .columns {
      margin: 0;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .column {
        padding: 0;
      }
    }

    .buttons-wrapper {
      margin-top: 50px;

      .cancel-clean {
        float: right;
      }
    }
  }

  &_multi-title {
    padding: 0 0 30px;

    .hc-reg {
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
    }
  }

  .multiselect {
    .multiselect__tags {
      border: none;
    }

    .multiselect__select {
      height: auto;
      width: auto;
      top: 7px;
      z-index: 99;

      &:before {
        font-family: "gire-icons", sans-serif !important;
        content: "\e900";
        font-size: 12px;
        top: 0;
        border: 0;
        color: $blue-midnight;
      }
    }

    .multiselect__content-wrapper {
      position: relative;
      background: #fff;
      width: 100%;
      min-width: 120px;
      max-height: 240px;
      overflow: auto;
      border: 0;
      border-radius: 0;
    }

    .multiselect__option {
      padding: 8px 16px;
      min-height: 32px;
      line-height: 16px;
      font-weight: 400;
      color: $blue-lynch;
    }

    .multiselect__option--highlight {
      background: $pale-blue;
      font-weight: 600;
      color: $blue-midnight;
    }

    .multiselect__placeholder {
      padding: 0;
      margin: 0;
      font-size: 12px;
    }

    .multiselect__element {
      display: block;
      width: 70%;
      margin: 0 auto;
    }
  }
}
</style>
