import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Translations } from '../lang/languages'

import { LOCAL_STORAGE } from '../utils/constants/localStorageItems'

Vue.use(VueI18n)

let language = ''
const localLang = window.localStorage.getItem(LOCAL_STORAGE.LANG)

if (localLang) {
  language = localLang
} else {
  language = 'en'
  window.localStorage.setItem(LOCAL_STORAGE.LANG, 'en')
}

export const i18n = new VueI18n({
  locale: language,
  fallbackLocale: 'en',
  messages: Translations
})
