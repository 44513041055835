import Vue from 'vue';
import Vuex from 'vuex';

import userStore from './modules/userStore';
import appStore from './modules/appStore';
import fleetStore from './modules/fleetStore';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isCardVisible: false,
    bannerText: '',
    isProcessing: false
  },
  mutations: {
    showCard(state, visibility) {
      state.isCardVisible = visibility;
    },
    setBannerText(state, text) {
      state.bannerText = text;
    },
    toggleProcessing(state) {
      state.isProcessing = !state.isProcessing;
    }
  },
  modules: {
    userStore,
    appStore,
    fleetStore,
  },
});

export default store;
