<template>
    <div>
        <PageLoading v-if="loading" />
        <div v-else class="input-basic">
            <label>{{ $t('alert.hc_list') }}</label>
            <select
            style="font-size:15px"
            v-model="selected">
            <option value="#">{{ $t('alert.list_heli') }}</option>
            <option v-for="hc in hcList" v-bind:value="hc" v-bind:key="hc.registration">
                {{ hc.registration }}
            </option>
            </select>
        </div>
        <div style="color: #D8000C; background-color: #FFD2D2;" v-if="error != ''">
          {{error}}
        </div>
        <div class="columns is-centered modal-retrieve__buttons-wrapper">
            <a @click="closeModal" class="button btn validationBtn cancel-clean">{{ $t('alert.button_cancel') }}</a>
            <a class="button btn validationBtn" @click="addOneAlert()">{{ $t('alert.button_add') }}</a>
            <a class="button btn validationBtn" @click="addAllAlert()">{{ $t('alert.button_add_all') }}</a>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageLoading from '../../views/PageLoading'

export default {
  components: {
    PageLoading
  },
  mounted () {
    this.loadHc()
    console.log('1')
    console.log(this.group)
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      hcList: [],
      selected: '#',
      error: '',
      day: 0,
      hour: 0,
      addHcList: [],
      loading: true
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    loadHc () {
      this.$requester
        .get(
          '/alert_helicopters'
        ).then(response => {
          this.hcList = response.data.helicopters
          this.loading = false
        }).catch(error => {
          console.log(error)
        })
    },
    addAlert () {
      if (this.addHcList !== ['#']) {
        console.log('2')
        console.log(this.group)
        let formData = new FormData()
        formData.append('alerts', JSON.stringify(this.addHcList))
        formData.append('group', JSON.stringify(this.group))
        this.$requester
          .post(`/create_alert`, formData)
          .then(response => {
            this.error = ''
            this.closeModal()
            this.$emit('reload')
            this.addHcList = []
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    addAllAlert () {
      this.addHcList = this.hcList
      this.addAlert()
    },
    addOneAlert () {
      this.addHcList.push(this.selected)
      this.addAlert()
    }
  }
}
</script>
