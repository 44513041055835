<template>
  <div class="app-modal-upload-body">
    <PageLoading v-if="waiting_import" :message="$t('importFiles.waiting')" />
    <div v-else>
      <div v-if="helicopter" class="columns app-modal-upload-body_title">
        <label class="column is-12 has-text-centered">
          {{ $t('importFiles.sub_title') }}
          <span>{{ helicopter.registration }}</span>
        </label>
      </div>
      <div class="app-modal-upload-body_content">
        <!-- Import files modal -->
        <div class="columns">
          <label class="label label-btn column is-5">{{ $t('uploadModal.input_label') }}</label>
          <span class="column is-5">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, off }">
                <label :for="helicopter.serial" class="inputStyle" v-on="on">{{ $t('config.browse') }}</label>
              </template>
              <input :id="helicopter.serial" @change="getInputData()" type="file" accept=".zip" :name="helicopter.serial">
              <span> {{ $t('importFiles.tooltip') }} </span>
            </v-tooltip>
            <span class="btn-status" :class="{ selectedColor: selectedColor }">{{ fileName }}</span>
          </span>
        </div>

        <div class="columns">
          <label class="label label-btn column is-5">{{ $t('uploadModal.input_label_folder') }}</label>
          <span class="column is-5">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, off }">
                <label :for="helicopter.serial + '-dir'" class="inputStyle" v-on="on">{{ $t('config.browse') }}</label>
              </template>
              <input :id="helicopter.serial + '-dir'" ref="fileInput" @change="getDirData()" type="file"
                :name="helicopter.serial + '-dir'" webkitdirectory />
              <span> {{ $t('importFiles.tooltip') }} </span>
            </v-tooltip>
            <span class="btn-status" :class="{ selectedColor: selectedColor }">{{ directoryName }}</span>
          </span>
        </div>

        <!-- Buttons -->
        <div class="columns is-centered buttons-wrapper">
          <a @click="closeModal" class="button btn column cancel-clean is-5">{{ $t('global.button_cancel') }}</a>
          <a :disabled="isUploadBtnDisabled" @click="uploadFiles()" class="button btn validationBtn column is-5">{{
            $t('global.button_import') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import utils from '../../mixins/utils'
import PageLoading from '../../views/PageLoading'

export default {
  name: 'ModalImportFiles',
  props: {
    helicopter: {
      type: Object,
      required: false
    }
  },
  components: {
    PageLoading
  },
  data() {
    return {
      inputStatus: [],
      uploadDisabled: true,
      selectedColor: false,
      isFileSelected: 'empty',
      isDirectorySelected: 'empty',
      fileName: this.$t('inputs.empty'),
      directoryName: this.$t('inputs.empty_dir'),
      waiting_import: false
    }
  },
  computed: {
    isUploadBtnDisabled() {
      return (this.uploadDisabled)
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    getInputData() {
      let elementFileID = 'hmiFiles'
      let oldElementFileID = 'dirHmiFiles'
      if (this.helicopter) {
        elementFileID = this.helicopter.serial
        oldElementFileID = (this.helicopter.serial + '-dir')
      }
      let importedFile = document.getElementById(elementFileID)
      //document.getElementById(oldElementFileID).value = ''
      if (importedFile.files[0]) {
        this.uploadDisabled = false
        this.inputStatus = importedFile.files
        this.fileName = importedFile.files[0].name
        this.directoryName = this.$t('inputs.empty')
        setTimeout(() => {
          this.isFileSelected = 'selected'
          this.isDirectorySelected = 'empty'
          this.selectedColor = true
        }, 800)
      }
    },
    getDirData() {
      let elementFileID = 'dirHmiFiles'
      let oldElementFileID = 'hmiFiles'
      if (this.helicopter) {
        elementFileID = (this.helicopter.serial + '-dir')
        oldElementFileID = this.helicopter.serial
      }
      let importedFile = document.getElementById(elementFileID)
      //document.getElementById(oldElementFileID).value = ''

      if (importedFile.files[0]) {
        this.uploadDisabled = false
        this.inputStatus = importedFile.files
        this.directoryName = importedFile.files[0].webkitRelativePath.split('/')[0]
        this.fileName = this.$t('inputs.empty')
        setTimeout(() => {
          this.isDirectorySelected = 'selected'
          this.isFileSelected = 'empty'
          this.selectedColor = true
        }, 800)
      }
    },
    async uploadFiles() {
      this.waiting_import = true
      this.uploadDisabled = true
      let formData = new FormData()
      if (this.helicopter) {
        formData.append('registration', this.helicopter.registration)
        formData.append('serial', this.helicopter.serial)
        formData.append('type', this.helicopter.type)
        //formData.append('zip_file', this.inputStatus[0])
        for (let i = 0; i < this.inputStatus.length; i++) {
          let isZipFile =
            formData.append('zip_file[' + i + ']', this.inputStatus[i])
        }
      } else {
        let selectedHelicopers = this.$store.state.fleetStore.selectedDetailed.map(h => {
          return {
            registration: h.registration,
            serial: h.serial,
            type: h.type
          }
        })
        formData.append('selected_helicopters', JSON.stringify(selectedHelicopers))
      }
      // Track the event (Posthug)

      const fileInput = this.$refs.fileInput;
      //const dtrum = window.dtrum; 
      if (fileInput && fileInput.files && fileInput.files.length > 0) {
        let totalSize = 0;
        for (let i = 0; i < fileInput.files.length; i++) {
          const file = fileInput.files[i];
          totalSize += file.size; 
        }
        const fileSizeMB = totalSize / (1024 * 1024)
        //dtrum.enterAction('Import Event', { file_size: fileSizeMB });
      }
       //Can't deal with zip for now
      /*else {
        dtrum.enterAction('Import Event')
      }*/
      await this.$requester
        .post('/import', formData, {
          header: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {
          this.waiting_import = false
          let fileNames = []
          if (response.data) {
            for (let el in response.data) {
              if (response.data[el].length > 0) {
                fileNames.push(response.data[el])
              }
            }
            if ("Successfully import".includes(fileNames) || "properly downloaded but missing optional function".includes(fileNames)) {
              this.$emit('responseSuccess', this.$t('uploadModal.succes'))
            } else {
              this.$emit('responseSuccess', this.$t('uploadModal.error') + `"${fileNames}"`)
            }
          }
        })
        .catch(error => {
          console.log('Error:', error.response.data)
          if (error.response) {
            //if (error.response.status === 409) {
            // missing field
            //}
            //this.$emit('responseError', `${error}`)
            if (error.response.status === 404) {
              let msg = this.$t('global.no_files_found')
              this.$emit('responseError', msg)
            } else if (error.response.status === 500) {
              // missing field
              let errMsg = this.$t(error.response.data.error)
              this.$emit('responseError', `${errMsg}`)
            } else {
              let msg = this.$t('global.nginx_timeout')
              this.$emit('responseError', msg)
            }
          } else {
            // missing field
          }
        })
    }
  }
}
</script>

<style lang="scss">
.app-modal-upload-body {
  $dark-blue: #002055;
  $pale-blue: #e6f3f6;
  $blue-lynch: #647491;
  $blue-midnight: #00205b;

  padding: 20px 50px;

  &_title {
    label {
      color: $dark-blue;
      font-size: 14px;
      font-weight: 400;
    }

    span {
      font-weight: 600;
    }
  }

  &_content {

    span,
    label {
      font-size: 14px;
      font-weight: 400;
      color: $dark-blue;

      .inputStyle {
        color: $blue-lynch;

        &:hover {
          color: #fff;
        }
      }
    }

    label {
      font-weight: 400;
      line-height: 2.5;
      color: $blue-lynch;
    }

    .btn-status {
      display: block;
      font-size: 12px;
      color: #647491;
    }

    .columns {
      margin: 0;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .column {
        padding: 0;
      }
    }

    .buttons-wrapper {
      margin-top: 50px;

      .cancel-clean {
        float: right;
      }
    }
  }

  .multiselect {
    .multiselect__tags {
      border: none;
    }

    .multiselect__select {
      height: auto;
      width: auto;
      top: 7px;
      z-index: 99;

      &:before {
        font-family: "gire-icons", sans-serif !important;
        content: "\e900";
        font-size: 12px;
        top: 0;
        border: 0;
        color: $blue-midnight;
      }
    }

    .multiselect__content-wrapper {
      position: relative;
      background: #fff;
      width: 100%;
      min-width: 120px;
      max-height: 240px;
      overflow: auto;
      border: 0;
      border-radius: 0;
    }

    .multiselect__option {
      padding: 8px 16px;
      min-height: 32px;
      line-height: 16px;
      font-weight: 400;
      color: $blue-lynch;
    }

    .multiselect__option--highlight {
      background: $pale-blue;
      font-weight: 600;
      color: $blue-midnight;
    }

    .multiselect__placeholder {
      padding: 0;
      margin: 0;
    }

    .multiselect__element {
      display: block;
      width: 70%;
      margin: 0 auto;
    }
  }
}
</style>
