<template>
    <div>
        <div class="input-basic">
            <label>{{ $t('alert.groupName') }}</label>
            <input
            v-model="groupName"
            class="reg-num"
            type="text"
            />
      </div>
      <div class="input-basic">
            <label>{{ $t('alert.day') }}</label>
            <input
            v-model="days"
            class="reg-num"
            type="text"
            />
      </div>
      <div class="input-basic">
            <label>{{ $t('alert.hour') }}</label>
            <input
            v-model="hours"
            class="reg-num"
            type="text"
            />
      </div>
      <div class="alert__error" v-if="error !==''">
        {{ $t(error) }}
      </div>
      <div class="columns is-centered modal-retrieve__buttons-wrapper">
        <a @click="closeModal" class="button btn cancel-clean">{{ $t('alert.button_cancel') }}</a>
        <a class="button btn validationBtn" @click="addAlertGroup()">{{ $t('alert.button_add') }}</a>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      error: '',
      groupName: '',
      days: 0,
      hours: 0
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    addAlertGroup () {
      if (this.groupName !== '') {
        if (this.validateTime()) {
          var group = {
            group_name: this.groupName,
            days: this.days,
            hours: this.hours
          }
          let formData = new FormData()
          formData.append('group', JSON.stringify(group))
          this.$requester
            .post(`/create_alert_group`, formData)
            .then(response => {
              this.error = ''
              this.closeModal()
              this.$emit('reload')
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          console.log('erreur')
          console.log(this.error)
        }
      } else {
        this.error = this.$t(`alert.msg_empty_group_name`)
      }
    },
    validateTime () {
      if (this.days.length > 0 && !parseInt(this.days)) {
        this.error = this.$t('alert.msg_only_digits')
        return false
      } else if (this.hours.length > 0 && !parseInt(this.hours)) {
        this.error = this.$t('alert.msg_only_digits')
        return false
      } else if (this.hours > 23 || this.hours < 0) {
        this.error = this.$t('alert.msg_invalid_data')
        return false
      } else if (this.hours < 0) {
        this.error = this.$t('alert.msg_invalid_data')
        return false
      } else {
        this.error = ''
        return true
      }
    }
  }
}
</script>
