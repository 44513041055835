<template>
  <div class="card" :class="{selectedHeli: this.isHeliChecked()}"  >
    <!-- Block View of Card Header -->
    <header v-if="mode === 'block'" class="card-header">
      <div >
        <span style='float:left;' @click="selectHeli" class="icon-heli icon"></span>
        <h1 style='float:right;' @click="selectHeli">{{ checkSession(helicopter.registration) }}</h1>
      </div>
      <HeliCardData
        class="status"
        :class="{connected: connect}"
        :data="checkHeliStatus(helicopter)"
      />
      <div>
        <table  aria-label="Signal Status Table" v-if="isMiniWacs(helicopter.type)" class="signal_table" align="center">
          <thead>
            <tr>
              <th id="col">
                <v-tooltip slot="append" bottom>
                  <div class="signal" :class="{disconnected: helicopter.vms === 0, inprogress: helicopter.vms === 1, finish: helicopter.vms === 2}" slot="activator"></div>
                  <span>{{ $t('helicard.tooltip_vms') + getStatus(helicopter.vms) }}</span>
                </v-tooltip>
              </th>

              <th id="col">
                <v-tooltip slot="append" bottom>
                  <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                  <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                </v-tooltip>
              </th>
              <th id="col">
                    <v-tooltip slot="append" bottom >
                      <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                      <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                    </v-tooltip>
                  </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="row" class="signal_description">U</td>
              <td id="row" class="signal_description">F</td>
              <td id="row" class="signal_description">L</td>
              <!--
                <td id="row" class="signal_description description_last_flight">L.F</td>
              -->
            </tr>
          </tbody>
        </table>
        <table  aria-label="Signal Status Table" v-else-if="isEC225(helicopter.type)" class='signal_table' align="center"  >
          <thead>
            <tr>
              <th id="col">
                <v-tooltip slot="append" bottom >
                  <div class="signal" :class="{disconnected: helicopter.hums === 0, inprogress: helicopter.hums === 1, finish: helicopter.hums === 2}" slot="activator"></div>
                  <span>{{ $t('helicard.tooltip_hums') + getStatus(helicopter.hums) }}</span>
                </v-tooltip>
              </th>
              <th id="col">
                <v-tooltip slot="append" bottom >
                  <div class="signal" :class="{disconnected: helicopter.healthRawData === 0, inprogress: helicopter.healthRawData === 1, finish: helicopter.healthRawData === 2}" slot="activator"></div>
                  <span>{{ $t('helicard.tooltip_healthRawData') + getStatus(helicopter.healthRawData) }}</span>
                </v-tooltip>
              </th>
              <th id="col">
                <v-tooltip slot="append" bottom >
                  <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                  <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                </v-tooltip>
              </th>
              <th id="col">
                <v-tooltip slot="append" bottom >
                  <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                  <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                </v-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="row" class="signal_description">H</td>
              <td id="row" class="signal_description">D</td>
              <td id="row" class="signal_description">F</td>
              <td id="row" class="signal_description">L</td>
              <!--
                <td id="row" class="signal_description description_last_flight">L.F</td>
              -->
            </tr>
            </tbody>
        </table>
        <table  aria-label="Signal Status Table" v-else class='signal_table' align="center" >
          <thead>
          <tr>
            <th id="col">
              <v-tooltip slot="append" bottom >
                <div class="signal" :class="{disconnected: helicopter.vms === 0, inprogress: helicopter.vms === 1, finish: helicopter.vms === 2}" slot="activator"></div>
                <span>{{ $t('helicard.tooltip_vms') + getStatus(helicopter.vms) }}</span>
              </v-tooltip>
            </th>
            <th id="col">
              <v-tooltip slot="append" bottom >
                <div class="signal" :class="{disconnected: helicopter.mnt === 0, inprogress: helicopter.mnt === 1, finish: helicopter.mnt === 2}" slot="activator"></div>
                <span>{{ $t('helicard.tooltip_mnt') + getStatus(helicopter.mnt) }}</span>
              </v-tooltip>
            </th>
            <th id="col">
              <v-tooltip slot="append" bottom >
                <div class="signal" :class="{disconnected: helicopter.hms === 0, inprogress: helicopter.hms === 1, finish: helicopter.hms === 2}" slot="activator"></div>
                <span>{{ $t('helicard.tooltip_hms') + getStatus(helicopter.hms) }} </span>
              </v-tooltip>
            </th>
            <th id="col">
              <v-tooltip slot="append" bottom >
                <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
              </v-tooltip>
            </th>
            <th id="col">
                    <v-tooltip slot="append" bottom >
                      <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                      <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                    </v-tooltip>
                  </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="row" class="signal_description">U</td>
            <td id="row" class="signal_description">M</td>
            <td id="row" class="signal_description">H</td>
            <td id="row" class="signal_description">F</td>
            <td id="row" class="signal_description">L</td>
            <!--
              <td id="row" class="signal_description description_last_flight">L.F</td>
            -->
          </tr>
          </tbody>
        </table>
      </div>
      <div class="action-links">
        <table  aria-label="Signal Status Table" >
          <thead>
            <tr>
              <th id="col"></th>
              <th id="col" v-if="isAdmin"></th>
              <th id="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="row">
                <div class="down-dot">
                  <span
                    class="icon-download-arrow"
                    @click.stop="activateRetrieveModal"
                    :class="{'animated-download' : helicopter.sending_files}"
                  ></span>
                </div>
              </td>
              <td id="row" v-if="isAdmin">
                <div class="upl-dot">
                  <span
                    class="icon-upload"
                    @click.stop="activateUploadModal"
                    :class="{'animated-upload' : helicopter.intransit_file > 0}"
                  >
                    <span
                      v-if="helicopter.pending_files !== 0"
                      class="pending-files"
                    >{{ helicopter.pending_files }}</span>
                  </span>
                </div>
              </td>
              <td id="row">
                <span class="vertical-dots-holder" v-on-clickaway="away">
                  <span class="icon-dots-vertical" @click.prevent="toggleImportDropdown"></span>
                  <ul class="operation-dropdown" v-if="importDropdownShow">
                    <li style="width:250px;" @click.stop="openImportModal">{{ $t('global.btn_import') }}</li>
                    <li v-if="isAHAdmin && helicopter.banned === false" style="width:250px;" @click.stop="openBanHCModal">{{ $t('global.button_ban_hc') }}</li>
                    <li v-if="isAHAdmin && helicopter.banned === true" style="width:250px;" @click.stop="openUnbanHCModal">{{ $t('global.button_unban_hc') }}</li>
                  </ul>
                  <span class="triangle-up"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </header>

    <!-- Table View of Card Header -->
    <header v-if="mode === 'table'" class="card-header">
      <div class="title-group" @click="selectHeli">
        <span class="icon-heli icon"></span>
        <h1 >{{ checkSession(helicopter.registration) }}</h1>
      </div>
      <div>
        <table  aria-label="Signal Status Table" class="table-view-table" >
          <thead>
              <tr>
                  <th class="col-2" >{{ $t('helicard.connection') }}</th>
                  <th class="col-1">{{ $t('helicard.connectivity') }}</th>
                  <th class="col-1">{{ $t('helicard.status') }}</th>
                  <th class="col-1">{{ $t('helicard.signals') }}</th>
                  <th class="col-1">{{ $t('helicard.actions') }}</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td id="row" class="col-2">
                      <heli-card-data :data="checkSession(lastConnectionFormatedDateAndTime)">
                          {{ $t('helicard.connection') }}
                      </heli-card-data>
                  </td>
                 <!--
                  <td id="row" class="col-1">
                      <InputSwitch v-if="isAdmin" :serial="helicopter.serial" :type="helicopter.type" :disabled="helicopter.active" />
                  </td>
                 --> 
                  <td id="row" class="col-2">
                      <div class="hc-data">
                          <label class="label-data">
                              <slot  >{{ $t('helicard.connectivity') }}</slot>
                          </label>
                          <p class="data" style="display: flex" v-if="helicopter.event === 'health'">
                              <span class="transfer-icon">!</span>
                              <router-link :to="{ name: 'management', query: { serial: helicopter.serial, type: helicopter.type }}">
                                  {{ $t('helicard.wacs_event') }}
                              </router-link>
                          </p>
                          <div class="data" style="display: flex" v-else-if="helicopter.event === 'error_download'">
                              <div style="float:left">
                                  <span class="event-icon"></span>
                              </div>
                              <div style="float:right">
                                  {{ $t('helicard.download_event') }}
                              </div>
                          </div>
                          <div class="data" style="display: flex" v-else-if="helicopter.event === 'upload_error'">
                              <div style="float:left">
                                  <span  class="event-icon"></span>
                              </div>
                              <div style="float:right">
                                  {{ $t('helicard.upload_error_event') }}
                              </div>
                          </div>
                          <div class="data" style="display: flex" v-else-if="helicopter.event === 'synchronized'">
                              <div style="float:left">
                                  <span class="event-icon"></span>
                              </div>
                              <div style="float:right">
                                  {{ $t('helicard.synchronized_event') }}
                              </div>
                          </div>
                          <div class="data" style="display: flex" v-else-if="helicopter.event === 'uploaded_files'">
                              <div style="float:left">
                                  <span class="event-icon"></span>
                              </div>
                              <div style="float:right">
                                  {{ $t('helicard.uploaded_files_event') }}
                              </div>
                          </div>
                          <div class="data" style="display: flex" v-else-if="helicopter.event === 'flight_session'">
                              <div style="float:left">
                                  <span class="event-icon"></span>
                              </div>
                              <div style="float:right">
                                  {{ $t('helicard.flight_session') }}
                              </div>
                          </div>
                          <div class="data" style="display: flex" v-else>
                              <div style="float:left">
                                  <span class="event-icon"></span>
                              </div>
                              <div style="float:right">
                                  {{ $t('helicard.no_event') }}
                              </div>
                          </div>
                      </div>
                  </td>
                  <td id="row" class="col-2">
                      <HeliCardData style="padding-top:10%;" class="status" :class="{connected: connect}" :data="checkHeliStatus(helicopter)" />
                  </td>
                  <td id="row" class="col-2" style="padding-left:5%;">
                      <table  aria-label="Signal Status Table" v-if="isMiniWacs(helicopter.type)" class='signal_table' align="center">
                          <thead>
                              <tr>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.vms === 0, inprogress: helicopter.vms === 1, finish: helicopter.vms === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_vms') + getStatus(helicopter.vms) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                                      </v-tooltip>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td id="row" class="signal_description">U</td>
                                  <td id="row" class="signal_description">F</td>
                                  <td id="row" class="signal_description">L</td>
                              </tr>
                          </tbody>
                      </table>
                      <table  aria-label="Signal Status Table" v-else-if="isEC225(helicopter.type)" class='signal_table' align="center" >
                          <thead>
                              <tr>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.hums === 0, inprogress: helicopter.hums === 1, finish: helicopter.hums === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_hums') + getStatus(helicopter.hums) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.healthRawData === 0, inprogress: helicopter.healthRawData === 1, finish: helicopter.healthRawData === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_healthRawData') + getStatus(helicopter.healthRawData) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                                      </v-tooltip>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td id="row" class="signal_description">H</td>
                                  <td id="row" class="signal_description">D</td>
                                  <td id="row" class="signal_description">F</td>
                                  <td id="row" class="signal_description">L</td>
                              </tr>
                          </tbody>
                      </table>
                      <table  aria-label="Signal Status Table" v-else class='signal_table' align="center" >
                          <thead>
                              <tr>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.vms === 0, inprogress: helicopter.vms === 1, finish: helicopter.vms === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_vms') + getStatus(helicopter.vms) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.mnt === 0, inprogress: helicopter.mnt === 1, finish: helicopter.mnt === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_mnt') + getStatus(helicopter.mnt) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.hms === 0, inprogress: helicopter.hms === 1, finish: helicopter.hms === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_hms') + getStatus(helicopter.hms) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                                      </v-tooltip>
                                  </th>
                                  <th id="col">
                                      <v-tooltip slot="append" bottom>
                                          <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                                          <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                                      </v-tooltip>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td id="row" class="signal_description">U</td>
                                  <td id="row" class="signal_description">M</td>
                                  <td id="row" class="signal_description">H</td>
                                  <td id="row" class="signal_description">F</td>
                                  <td id="row" class="signal_description">L</td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
                  <td id="row" class="col-2">
                      <div class="action-links">
                          <table  aria-label="Signal Status Table" class="action-links-table" >
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                            </tr>
                              <tr>
                                  <td id="row">
                                      <div class="down-dot">
                                          <span class="icon-download-arrow" @click.stop="activateRetrieveModal()" :class="{'animated-download' : helicopter.sending_files}"></span>
                                      </div>
                                  </td>
                                  <td id="row" v-if="isAdmin">
                                      <div class="upl-dot">
                                          <span class="icon-upload" @click.stop="activateUploadModal()" :class="{'animated-upload' : helicopter.intransit_file > 0}">
                                              <span v-if="helicopter.pending_files !== 0" class="pending-files">
                                                  {{ helicopter.pending_files }}
                                              </span>
                                          </span>
                                      </div>
                                  </td>
                                  <td id="row">
                                      <span class="vertical-dots-holder" v-on-clickaway="away">
                                          <span class="icon-dots-vertical" @click.prevent="toggleImportDropdown"></span>
                                          <ul class="operation-dropdown" v-if="importDropdownShow">
                                              <li style="width:250px;" @click.stop="openImportModal">
                                                  {{ $t('global.btn_import') }}
                                              </li>
                                              <li v-if="isAHAdmin && helicopter.banned === false" style="width:250px;" @click.stop="openBanHCModal">
                                                  {{ $t('global.button_ban_hc') }}
                                              </li>
                                              <li v-if="isAHAdmin && helicopter.banned === true" style="width:250px;" @click.stop="openUnbanHCModal">
                                                  {{ $t('global.button_unban_hc') }}
                                              </li>
                                          </ul>
                                          <span class="triangle-up"></span>
                                      </span>
                                  </td>
                              </tr>
                          </table>
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>

      </div>
      <button v-if="helicopter.banned == 0 || helicopter.banned == false"
        class="toggle-full-info"
        :class="[{ expand: expanded }]"
        @click="toggleExpanded"
      >
        <span class="icon-chevron-down" />
      </button>
    </header>

    <!-- Card Body -->
    <div class="card-body">
      <!-- Block View of Card Body -->
      <div :id="helicopter.id" v-if="mode === 'block'" class="content-body">
        <div class="data-row">

          <table  aria-label="Signal Status Table" class="info-data" >
            <tr>
              <th id="row"><div class="hc-data"><label class="label-data"><slot>{{ $t('helicard.air_sn') }}</slot></label></div></th>
              <th id="row"><div class="hc-data" ><label class="label-data"><slot>{{ $t('helicard.heli_type') }}</slot></label></div></th>
              <th id="row"><div class="hc-data"><label class="label-data"><slot>{{ $t('helicard.connection') }}</slot></label></div></th>
              <th id="row"><div class="hc-data"><label class="label-data"><slot>{{ $t('helicard.connectivity') }}</slot></label></div></th>
            <!--
              <th id="row" rowspan=2><InputSwitch v-if="isAdmin" :serial="helicopter.serial"  :type="helicopter.type" :disabled="helicopter.active" /></th>
            -->
            </tr>
            <tr>
              <td id="HC S/N" rowspan=2><div class="data">{{ checkSession(helicopter.serial) }}</div></td>
              <td id="HC Type" rowspan=2><div class="data">{{ checkSession(cleanData(helicopter.type)) }}</div></td>
              <td id="Last connection"><div class="data">{{ checkSession(lastConnectionFormatedDate) }}</div></td>
              <td id="row" rowspan=2>
                <div class="data" style="display: flex" v-if="helicopter.event === 'health'">
                  <span class="transfer-icon">!</span>
                  <router-link
                    :to="{ name: 'management', query: { serial: helicopter.serial, type: helicopter.type }}"
                  >{{ $t('helicard.wacs_event') }}</router-link>
                </div>
                <div class="data" style="display: flex" v-else-if="helicopter.event === 'error_download'">
                  <div style="float:left">
                    <span class="event-icon"></span>
                  </div>
                  <div class="text-event" style="float:right">
                    <p class="container-event"> {{ $t('helicard.download_event') }} </p>
                  </div>
                </div>
                <div class="data" style="display: flex" v-else-if="helicopter.event === 'upload_error'">
                  <div style="float:left">
                    <span class="event-icon"></span>
                  </div>
                  <div class="text-event" style="float:right">
                    <p class="container-event"> {{ $t('helicard.upload_error_event') }}  </p>
                  </div>
                </div>
                <div class="data" style="display: flex" v-else-if="helicopter.event === 'synchronized'">
                  <div style="float:left">
                    <span class="event-icon"></span>
                  </div>
                  <div class="text-event" style="float:right">
                    <p class="container-event"> {{ $t('helicard.synchronized_event') }} </p>
                  </div>
                </div>
                <div class="data" style="display: flex" v-else-if="helicopter.event === 'uploaded_files'">
                  <div style="float:left">
                    <span class="event-icon"></span>
                  </div>
                  <div class="text-event" style="float:right">
                    <p class="container-event"> {{ $t('helicard.uploaded_files_event') }} </p>
                  </div>
                </div>
                <div class="data" style="display: flex" v-else-if="helicopter.event === 'flight_session'">
                  <div style="float:left">
                    <span class="event-icon"></span>
                  </div>
                  <div class="text-event" style="float:right">
                    <p class="container-event"> {{ $t('helicard.flight_session') }} </p>
                  </div>
                </div>
                <div class="data" style="display: flex" v-else>
                  <div style="float:left">
                    <span class="event-icon"></span>
                  </div>
                  <div class="text-event" style="float:right">
                    <p class="container-event"> {{ $t('helicard.no_event') }}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr><td id="row"><div class="data">{{ checkSession(lastConnectionFormatedTime) }}</div></td></tr>
          </table>
        </div>
        <!-- Expanded -->
        <div class="full-info" v-if="expanded">
          <div v-if="loading" class="loader-container">
            <div class="loader"></div>
          </div>
          <div v-else class="full-info-data">
            <table  aria-label="Signal Status Table" v-if="isMiniWacs(helicopter.type)" class="hc-table">
              <thead>
                <tr>
                  <th id="col"></th>
                  <th scope = "col">HW Part Number</th>
                  <th scope = "col">HW S/N</th>
                  <th scope = "col">SW Part Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="row">AEC</td>
                  <td id="row">{{checkSession(helicopter.session.acs_hw_pn)}}</td>
                  <td id="row">{{checkSession(helicopter.session.acs_hw_sn)}}</td>
                  <td id="row">{{checkSession(helicopter.session.acs_sw_pn)}}</td>
                </tr>
              </tbody>
            </table>
            <table  aria-label="Signal Status Table" v-else class="hc-table">
              <thead>
                <tr>
                  <th scope = "col"></th>
                  <th scope = "col">HW Part Number</th>
                  <th scope = "col">HW S/N</th>
                  <th scope = "col">SW Part Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="row">ACS</td>
                  <td id="row">{{checkSession(helicopter.session.acs_hw_pn)}}</td>
                  <td id="row">{{checkSession(helicopter.session.acs_hw_sn)}}</td>
                  <td id="row">{{checkSession(helicopter.session.acs_sw_pn)}}</td>
                </tr>
                <tr>
                  <td id="row">CM</td>
                  <td id="row">{{checkSession(helicopter.session.cm_hw_pn)}}</td>
                  <td id="row">{{checkSession(helicopter.session.cm_hw_sn)}}</td>
                  <td id="row">{{checkSession(helicopter.session.cm_sw_pn)}}</td>
                </tr>
                <tr>
                  <td id="row">RMD</td>
                  <td id="row">{{checkSession(helicopter.session.rmd_hw_pn)}}</td>
                  <td id="row">{{checkSession(helicopter.session.rmd_hw_sn)}}</td>
                </tr>
              </tbody>
            </table>
            <tr>
              <table  aria-label="Signal Status Table" class="hc-table">
                <thead>
                  <tr>
                    <th colspan="2" scope = "col">{{ $t('helicard.location') }}</th>
                    <th scope = "col">Last Import</th>
                    <th scope = "col">Last Export</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td id="row">{{checkSession(helicopter.session.gps_lat)}}</td>
                    <td id="row">{{checkSession(helicopter.session.gps_lon)}}</td>
                    <td id="row">{{lastUploadFormatedDateAndTime}}</td>
                    <td id="row">{{lastDownloadFormatedDateAndTime}}</td>
                  </tr>
                </tbody>
              </table>
            </tr>
            <table  aria-label="Signal Status Table" v-if="isMiniWacs(helicopter.type)" class="hc-table">
              <thead>
                <tr id="acs">
                  <th id="col">{{ $t('helicard.aec_config') }}</th>
                  <th id="col">{{ $t('helicard.aec_custo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="row">{{checkSession(helicopter.session.acs_config)}}</td>
                  <td id="row">{{checkSession(helicopter.session.acs_custom)}}</td>
                </tr>
              </tbody>
            </table>
            <table  aria-label="Signal Status Table" v-else class="hc-table">
              <thead>
                <tr id="acs">
                  <th id="col">{{ $t('helicard.acs_config') }}</th>
                  <th id="col">{{ $t('helicard.acs_custo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="row">{{checkSession(helicopter.session.acs_config)}}</td>
                  <td id="row">{{checkSession(helicopter.session.acs_custom)}}</td>
                </tr>
              </tbody>
            </table>
            <div class="last-update">
              <i>{{ lastUpdateFormatedDateAndTime }}</i>
            </div>
          </div>
        </div>
        <button v-if="helicopter.banned == 0 || helicopter.banned == false"
          class="toggle-full-info"
          :class="[{ expand: expanded }]"
          @click="toggleExpanded"
        >
          <span class="icon-chevron-down"></span>
        </button>
      </div>

      <!-- Table View of Card Body -->
      <!-- Expanded -->
      <div :id="helicopter.id" v-if="mode === 'table'" class="content-body">
        <div class="full-info" v-if="expanded">
            <div v-if="loading" class="loader-container">
                <div class="loader"></div>
            </div>
            <div v-else class="full-info-data">
                <div class="data-extention">
                    <table  aria-label="Signal Status Table">
                      <tr>
                        <th></th>
                        <th></th>
                      </tr>
                        <tr>
                            <td id="row">
                                <heli-card-data :data="checkSession(helicopter.serial)">{{ $t('helicard.air_sn') }}</heli-card-data>
                            </td>
                            <td id="row">
                                <heli-card-data :data="checkSession(cleanData(helicopter.type))">{{ $t('helicard.heli_type') }}</heli-card-data>
                            </td>
                        </tr>
                    </table>
                </div>
                <table  aria-label="Signal Status Table" v-if="isMiniWacs(helicopter.type)" class="table-container">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>

                  </tr>
                    <tr>
                        <td id="row">
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">HW Part Number</th>
                                        <th scope="col">HW S/N</th>
                                        <th scope="col">SW Part Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="row">AEC</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_hw_pn)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_hw_sn)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_sw_pn)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td id="row">
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                    <tr>
                                        <th id="col">Last Import</th>
                                        <th id="col">Last Export</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="row">{{lastUploadFormatedDateAndTime}}</td>
                                        <td id="row">{{lastDownloadFormatedDateAndTime}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                    <tr>
                                        <th colspan="2">{{ $t('helicard.location') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="row">{{checkSession(helicopter.session.gps_lat)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.gps_lon)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td id="row">
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                  <tr id="acs">
                                        <th id="col">{{ $t('helicard.aec_config') }}</th>
                                        <th id="col">{{ $t('helicard.aec_custo') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                        <td id="row">{{checkSession(helicopter.session.acs_config)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_custom)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </table>
                <table  aria-label="Signal Status Table" v-else class="table-container">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                    <tr>
                        <td id="row">
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">HW Part Number</th>
                                        <th scope="col">HW S/N</th>
                                        <th scope="col">SW Part Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="row">ACS</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_hw_pn)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_hw_sn)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_sw_pn)}}</td>
                                    </tr>
                                    <tr>
                                        <td id="row">CM</td>
                                        <td id="row">{{checkSession(helicopter.session.cm_hw_pn)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.cm_hw_sn)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.cm_sw_pn)}}</td>
                                    </tr>
                                    <tr>
                                        <td id="row">RMD</td>
                                        <td id="row">{{checkSession(helicopter.session.rmd_hw_pn)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.rmd_hw_sn)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td id="row">
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                    <tr>
                                        <th id="col">Last Import</th>
                                        <th id="col">Last Export</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="row">{{lastUploadFormatedDateAndTime}}</td>
                                        <td id="row">{{lastDownloadFormatedDateAndTime}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                    <tr>
                                        <th colspan="2">{{ $t('helicard.location') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="row">{{checkSession(helicopter.session.gps_lat)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.gps_lon)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td id="row">
                            <table  aria-label="Signal Status Table" class="hc-table inline-table">
                                <thead>
                                  <tr id="acs">
                                        <th id="col">{{ $t('helicard.acs_config') }}</th>
                                        <th id="col">{{ $t('helicard.acs_custo') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                        <td id="row">{{checkSession(helicopter.session.acs_config)}}</td>
                                        <td id="row">{{checkSession(helicopter.session.acs_custom)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>

</div>
    <!-- Retrieve Modal -->
    <modal :modalName="retrieveModal.name" :title="retrieveModal.title" width="774px">
      <ModalRetrieve
        :helicopter="helicopter"
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <!-- Upload Modal -->
    <modal :title="uploadFilesModal.title" :modalName="uploadFilesModal.name" width="460px">
      <ModalUploadFiles
        :helicopter="helicopter"
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <!-- Confirm modal -->
    <modal :modalName="confirmModal.name">
      <ModalConfirm
        :message="confirmModal.message"
        :error="confirmModal.error"
        @modalConfirmed="closeModal()"
      />
    </modal>
    <!-- Import File -->
    <modal :title="importFiles.title" :modalName="importFiles.name" width="460px">
      <ModalImportToGround
        :helicopter="importFiles.props"
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <!-- Ban Helicopter -->
    <modal :title="banHCModal.title" :modalName="banHCModal.name" width="460px">
      <ModalBanHC 
        :helicopter="banHCModal.props"
        @responseSuccess="modalResponseSuccess" 
        @responseError="modalResponseError"
      />
    </modal>
    <!-- Unban Helicopter -->
    <modal :title="unbanHCModal.title" :modalName="unbanHCModal.name" width="460px">
      <ModalUnbanHC
        :helicopter="unbanHCModal.props" 
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import HeliCardData from './HeliCardData'
import Modal from '../modals/Modal'
import ModalConfirm from '../modals/ModalConfirm'
import ModalRetrieve from '../modals/ModalRetrieve'
import ModalImportToGround from '../modals/ModalImportToGround'
import ModalUploadFiles from '../modals/ModalUploadFiles'
import ModalBanHC from '../modals/ModalBanHC'
import ModalUnbanHC from '../modals/ModalUnbanHC'
import { directive as onClickaway } from 'vue-clickaway'
import utils from '../../mixins/utils'
import axios from 'axios';

var offsetUTC = -(new Date().getTimezoneOffset()/60) > 0 ? '+' + -(new Date().getTimezoneOffset()/60) : -(new Date().getTimezoneOffset()/60)

export default {
  directives: {
    onClickaway: onClickaway
  },
  name: 'HeliCard',
  mixins: [utils],
  props: {
    mode: {
      type: String,
      required: true
    },
    fleetName: {
      type: String,
      required: true
    },
    helicopter: {
      type: Object,
      required: true
    }
  },
  components: {
    HeliCardData,
    Modal,
    ModalRetrieve,
    ModalConfirm,
    ModalImportToGround,
    ModalUploadFiles,
    ModalBanHC,
    ModalUnbanHC
  },
  data () {
    return {
      retrieveModal: {
        // When modal is in for loop, the name must be completely unique.
        name: 'retrieveModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'retrieveModal.title'
      },
      uploadFilesModal: {
        name: 'ModalUploadFiles' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'uploadModal.title'
      },
      unbanHCModal: {
        name: 'ModalUnbanHC' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'unbanHCModal.title'
      },
      banHCModal: {
        name: 'ModalBanHC' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'banHCModal.title'
      },
      importFiles: {
        name: 'importFiles' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'importFiles.title'
      },
      confirmModal: {
        name: 'confirmSuccessfulllUpload' + (this.helicopter.type + '/' + this.helicopter.serial),
        message: '',
        error: false
      },
      expanded: false,
      connect: false,
      flagDate: false,
      transferData: true,
      importOption: false,
      uploadOpen: false,
      importDropdownShow: false,
      loading: false,
    }
  },
  computed: {
    ...mapState(['userStore']),
    ...mapState('appStore', ['lang']),
    ...mapState('fleetStore', ['selected', 'isHeliAdded']),
    ...mapGetters('fleetStore', ['isHeliAdded']),
    ...mapGetters('userStore', ['isAdmin', 'isAHAdmin']),
    localModel () {
      return this.selected
    },
    lastConnectionFormatedDate () {
      if (!this.helicopter.last_connexion || this.helicopter.last_connexion == "0000-00-00 00:00:00") return "Never connected"

      let lTime = this.$moment(this.getLastConnexionGmt()).format(
        'D MMM YYYY'
      )

      return this.checkError(lTime)
    },
    lastConnectionFormatedTime () {
      if (!this.helicopter.last_connexion || this.helicopter.last_connexion == "0000-00-00 00:00:00") return ' '

      let lTime = this.$moment(this.getLastConnexionGmt()).format(
        'HH:mm'
      ) + ' UTC' + offsetUTC

      return this.checkError(lTime)
    },
    lastConnectionFormatedDateAndTime () {
      if (!this.helicopter.last_connexion) return 'n/a'

      let lTime = this.$moment(this.getLastConnexionGmt()).format(
        'D MMM YYYY HH:mm'
      ) + ' UTC' + offsetUTC

      return this.checkError(lTime)
    },
    lastDownloadFormatedDateAndTime () {
      if (!this.helicopter.last_download) return 'n/a'

      let lTime = this.$moment(this.helicopter.last_download + ' GMT').format(
        'D MMM YYYY HH:mm'
      )

      return this.checkError(lTime)
    },
    lastUploadFormatedDateAndTime () {
      if (!this.helicopter.last_upload) return 'n/a'

      let lTime = this.$moment(this.helicopter.last_upload + ' GMT').format(
        'D MMM YYYY HH:mm'
      )

      return this.checkError(lTime)
    },
    lastUpdateFormatedDateAndTime () {
      if (!this.helicopter.session.last_update  || this.helicopter.session.last_update == "N/A") return ''
      let lTime = this.$moment(this.helicopter.session.last_update + ' GMT').format(
        'D MMM YYYY HH:mm'
      ) + ' UTC' + offsetUTC

      return "Last update: " + this.checkError(lTime)
    },
    refreshImportFiles () {
      this.importFiles.name = 'importFiles' + (this.helicopter.type + '/' + this.helicopter.serial)
      this.importFiles.props = this.helicopter
      return this.importFiles
    },
    refreshBan () {
      this.banHCModal.name = 'ModalBanHC' + (this.helicopter.type + '/' + this.helicopter.serial)
      this.banHCModal.props = this.helicopter
      return this.banHCModal
    },
    refreshUnban () {
      this.unbanHCModal.name = 'ModalUnbanHC' + (this.helicopter.type + '/' + this.helicopter.serial)
      this.unbanHCModal.props = this.helicopter
      return this.unbanHCModal
    }
  },
  methods: {
    getLastConnexionGmt () {
      return this.helicopter.last_connexion + ' GMT'
    },
    checkError (time) {
      if(time.includes('Invalid')){
        return 'n/a'
      }
      else return time
    },
    toggleImportDropdown () {
      if (this.importDropdownShow) {
        this.importDropdownShow = false
      } else {
        this.importDropdownShow = true
      }
    },
    onClose () {
      this.importDropdownShow = false
    },
    ...mapActions('fleetStore', ['setFleet', 'addMachine', 'removeMachine']),
    ...mapActions('appStore', ['openModal', 'closeModal']),
    ...mapActions('userStore', ['isAdmin']),
    selectHeli () {
      if (this.isHeliChecked()) {
        this.removeMachine(this.helicopter)
      } else {
        this.addMachine(this.helicopter)
      }
    },
    isHeliChecked () {
      return this.localModel.includes((this.helicopter.type + '/' + this.helicopter.serial))
    },
    activateImportModal () {
      this.openModal(this.refreshImportFiles.name)
    },
    activateBannedModal() {
      this.openModal(this.refreshBan.name)
    },
    activateUnbannedModal() {
      this.openModal(this.refreshUnban.name)
    },
    activateRetrieveModal () {
      this.openModal(this.retrieveModal.name)
    },
    activateUploadModal () {
      this.openModal(this.uploadFilesModal.name)
    },
    modalResponseSuccess (msg) {
      this.confirmModal.error = false
      this.confirmModal.message = msg
      this.closeModal()
      this.openModal(this.confirmModal.name)
    },
    modalResponseError (errorMsg) {
      this.confirmModal.error = true
      this.confirmModal.message = errorMsg
      this.closeModal()
      this.openModal(this.confirmModal.name)
    },
    checkSession (data) {
      let status = [null, undefined, 0, '']
      if (status.includes(data)) {
        data = 'n/a'
      }
      return data
    },

  fetchDetailledCard() {
    this.loading = true; 
    return this.$requester
      .get(`/detailled_card`, { params: { type: this.helicopter.type, serial: this.helicopter.serial }}
      )
      .then(response => {
        this.helicopter.session = response.data.fleets[0].aircrafts[0].session;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  toggleExpanded() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.fetchDetailledCard();
      }
    },

    checkHeliStatus (data) {
      let status = ''
      if(this.helicopter.banned == true || this.helicopter.banned > 0){
        status = this.$t('helicard.banned')
        this.flagDate = false
        this.connect = false
      }
      else if (this.helicopter.connected > 0) {
        // status = 'CONNECTED'
        status = this.$t('helicard.connected')
        this.flagDate = true
        this.connect = true
      } else {
        // status = 'DISCONNECTED'
        status = this.$t('helicard.disconnected')
        this.flagDate = false
        this.connect = false
      }
      return status
    },
    openImportModal () {
      this.activateImportModal()
      this.importDropdownShow = false
    },
    openBanHCModal () {
      this.activateBannedModal()
      this.importDropdownShow = false
    },
    openUnbanHCModal () {
      this.activateUnbannedModal()
      this.importDropdownShow = false
    },
    away () {
      this.importDropdownShow = false
    },
    isMiniWacs (type) {
      return (type === 'AS350_B3' || type === 'EC130_T2' || type === "EC135_T4")
    },
    isEC225 (type) {
      return (type === 'EC225_LP')
    },
    getStatus (statusDigit) {
      switch (statusDigit) {
        case 1:
          return ' : in progress'
        case 2:
          return ' : transfer completed'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss">

.v-application ol, .v-application ul {
    padding-left: 6px !important;
}
.v-application p {
     margin-bottom: 0px !important; 
}
.signal {
  box-shadow: 1px 1px 3px #555;
  border: 2px solid #9AA4B7;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}
.disconnected {
  background: radial-gradient(#CAC9C8, #DCE1E0);
}
.inprogress {
  background: radial-gradient(#F48728, #F7B377);
}
.finish {
  background: radial-gradient(#29AC43, #9CF76F);
}
.signal_table {
  margin-left:10px;
  tr th {
    padding: 2px;
  }
  .signal_description {
    font-size: 14px;
    text-align: center;
    color: #b4b4b4;
  }
  .signal_last_flight {
    padding-left: 15px;
  }
  .description_last_flight {
    padding-left: 15px;
  }
}
.table-view-table {
  vertical-align:center;
  margin-left:3%;
  width:100%;
  .col-1{
    padding-left:2%;
    width:10%;
  }
  .col-2{
    padding-left:2%;
    width:20%;
  }
}
.data-extention {
  width:100%;
}
.vertical-dots-holder {
  padding-left: 10px;
}
.action-links-table {
  margin-top:3%;
}

  .loader-container {
  position: relative;
  height: 100%; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  text-align: center;
}
.last-update {
  font-style: italic;
  color: gray;
  margin-top: 5px;
  font-size: 0.8rem;
}
.container-event {
  max-width: 150px;
  max-height: 40px; 
  overflow: hidden;
  line-height: 20px; 
  transition: max-height 0.3s ease-in-out;
}

.text-event {
  margin-left: 5px;
  cursor: pointer; 
}

.text-event:hover .container-event {
  max-height: 1000px; 
  white-space: normal;
}
</style>