<template>
  <div>
    <PageLoading v-if="requsterProcesing" />
    <div v-else class="modal-retrieve" :class="{ 'hide-content-on-loading': this.requsterProcesing }">
      <div class="modal-retrieve__registrations-list">
        <label>{{ $t('retrieveMultiModal.sub_title') }}</label>
        <div class="columns is-multiline is-gapless retrive-multi__registrations">
          <div class="column is-3">{{ helicopter.registration }}</div>
        </div>
      </div>
      <div class="modal-retrieve__content">
        <!-- File types -->
        <div class="columns">
          <span>{{ $t('sessionFilters.file_types') }}</span>
        </div>
        <div class="columns">
          <ModalRetrieveFileTypes ref="modalRetrieveFileTypes" :heli="helicopter" />
        </div>
        <!-- Filters -->
        <div class="columns">
          <label class="title-label">{{ $t('sessionFilters.filter') }}</label>
        </div>
        <ModalRetrieveFilterBy ref="modalRetrieveFilterBy" :heli="helicopter" />
        <!-- Buttons -->
      </div>
      <div class="columns is-centered modal-retrieve__buttons-wrapper">
        <a @click="closeModal" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
        <a :disabled="!isValid()" class="button btn validationBtn" @click="retrieveFiles()">{{ $t('global.button_retrieve') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import convertZipResponse from '../../utils/convertZipResponse'

import PageLoading from '../../views/PageLoading'
import ModalRetrieveFilterBy from './ModalRetrieveFilterBy'
import ModalRetrieveFileTypes from './ModalRetrieveFileTypes'

export default {
  name: 'ModalRetrieve',
  components: {
    ModalRetrieveFilterBy,
    ModalRetrieveFileTypes,
    PageLoading
  },
  props: {
    helicopter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      requsterProcesing: false,
      modal: false
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    isValid() {
      if (this.$refs.modalRetrieveFilterBy != null && this.$refs.modalRetrieveFilterBy.getFiltersData().option != null) {
        return true
      } else {
        return false
      }
    },
    retrieveFiles() {
      if (!this.isValid()) {
        return
      }
      let formData = new FormData()
      let dataFromFilters = this.$refs.modalRetrieveFilterBy.getFiltersData()
      let dataFromTypes = this.$refs.modalRetrieveFileTypes.getTypesData()

      this.loading = true
      this.requsterProcesing = true

      formData.append('registration', this.helicopter.registration)
      formData.append('serial', this.helicopter.serial)
      formData.append('type', this.helicopter.type)
      formData.append('selected_files', dataFromTypes)
      for (let filter in dataFromFilters) {
        formData.append(filter, dataFromFilters[filter])
      }
      this.$requester
        .post('/download', formData, {})
        .then(response => {

          this.loading = false
          this.requsterProcesing = false
          const parts = response.data.msg.split("/");
          const filename = parts[parts.length - 1];
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href =  response.data.msg;
          a.download = filename
          document.body.appendChild(a);
          let incompletedSessionNumber = response.headers.incompletedsession
          let SelectedSession = response.headers.selectedsession

          let inCompletedSessionMsg = this.$t('global.partial_sessions').replace("x1", incompletedSessionNumber) 
          if(incompletedSessionNumber != SelectedSession){
            inCompletedSessionMsg = inCompletedSessionMsg.replace("x2", SelectedSession)
          } else {
            // not clear why we have this condition, should we send another message if client did not download all the session but all the expected file
            inCompletedSessionMsg = this.$t('global.partial_sessions2')
          }
          let msg = (incompletedSessionNumber == 0) ? 
          this.$t('global.download_success') 
          : inCompletedSessionMsg;
          a.click();
          this.$emit('responseSuccess', msg)
          const dtrum = window.dtrum; 
          dtrum.enterAction('Retrieve Event')
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 404) {
              let msg = this.$t('global.no_files_to_import')
              this.$emit('responseError', msg)
            } else if (error.response.status === 500) {
              // missing field
              let errMsg = this.$t('errorMsg.err')
              this.$emit('responseError', `${errMsg}`)
            } else {
              let msg = this.$t('global.nginx_timeout')
              this.$emit('responseError', msg)
            }
          }
        })
    }
  }
}
</script>
<style lang="scss">
