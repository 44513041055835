import Vue from 'vue'
import PortalVue from 'portal-vue'

import App from './App'
import router from './router'
import { i18n } from './lang/i18n'
import store from './store'
import ApiService from './utils/apiService'
import Vuetify from 'vuetify'
import Multiselect from 'vue-multiselect'

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import 'bulma/css/bulma.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './assets/sass/styles.scss'

import moment from 'moment'

Vue.config.productionTip = false
Vue.prototype.$requester = ApiService
Vue.prototype.$moment = moment

Vue.use(Vuetify, { iconfont: 'mdi' })
Vue.use(PortalVue)
Vue.component('Multiselect', Multiselect)

const vuetify = new Vuetify({
  theme: {
    dark: false,  // Set your default theme here
  }
})

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')