<template>
  <div class="general-view alert">
    <div class="fixed-bar"></div>
    <PageLoading v-if="requesterProcessing" />
    <div v-else class="alert__container">
      <div  v-if="canFilter" :style ="{background: linearGradientStyle}" class="columns action-bar filter">
        <div class="column is-5">
          <input
            class="vuetify-input-fix filter-input"
            type="text"
            name="filter"
            v-model="filter"
            :placeholder="$t('alert.filter_placeholder')"
            @keyup.enter="loadAlertGroups"
          />
        </div>
      </div>
      <section style='margin-left: 5%; width: 90%; margin-bottom:50px' class="content-wrap">
        <Description :texts="[$t(`alert.description`)]" style="margin-bottom:10px"></Description>
        <table  aria-label="Signal Status Table" class="alert__button_text" @click="openAddAlertGroupModal()">
          <tr>
            <th id="col"></th>
            <th id="col"></th>
          </tr>
          <tr>
            <td id="row" style="padding:3px 3px 0px 3px">
              <svg slot="activator" fill="#005587" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
              </svg>
            </td>
            <td id="row" style="padding:1px 3px 0px 3px">
              {{ $t('alert.add_alert_group') }}
            </td>
          </tr>
        </table>
        <div  v-for="group in alertGroups" v-bind:key="group.id">
          <AlertGroupCard
            v-bind:group="group"
            @reload="loadAlertGroups"
          />
        </div>
      </section>
    </div>
    <modal :title="addAlertGroupModal.title" :modalName="addAlertGroupModal.name" width="430px">
      <ModalAddAlertGroup
        @reload="loadAlertGroups"
      />
    </modal>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import NavLayout from '@/layouts/NavLayout'
import AlertGroupCard from '../components/AlertGroupCard'
import Description from '../components/Description'
import PageLoading from './PageLoading'
import addIcon from '../assets/images/add.svg'
import ModalAddAlertGroup from '../components/modals/ModalAddAlertGroup'
import Modal from '../components/modals/Modal'

export default {
  components: {
    AlertGroupCard,
    PageLoading,
    Description,
    ModalAddAlertGroup,
    Modal
  },
  data () {
    return {
      addAlertGroupModal: {
        name: 'addAlertGroupModal',
        title: 'alert.addAlertGroupModal_title'
      },
      filter: '',
      fetchingFleetsTimeoutId: null,
      requesterProcessing: false,
      alertGroups: {}
    }
  },
  computed: {
    ...mapState(['userStore']),
    ...mapGetters('userStore', ['canFilter']),
    path () {
      return addIcon
    },
    linearGradientStyle(){
      const startColor = process.env.VUE_APP_BACKGROUND_1
      const endColor = process.env.VUE_APP_BACKGROUND_2
      return `linear-gradient(to left, ${startColor}, ${endColor})`
    },
  },
  created () {
    this.$emit('update:layoutComponent', NavLayout)
  },
  mounted () {
    this.requesterProcessing = true
    this.loadAlertGroups()
  },
  beforeRouteLeave (to, from, next) {
    clearTimeout(this.fetchingFleetsTimeoutId)
    next()
  },
  methods: {
    ...mapActions('appStore', ['openModal', 'closeModal']),
    loadAlertGroups () {
      return this.$requester
        .get(`/alert_groups`, { params: { filter: this.filter !== '' ? this.filter : null } })
        .then(response => {
          this.alertGroups = [...response.data.groups]
          this.requesterProcessing = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    async fetchFleetsPolling () {
      // A check to ensure if the component it's destroyed to stop fetching
      if (this._isDestroyed || this._isBeingDestroyed) {
        return
      }
      await this.loadAlertGroups()
      this.fetchingFleetsTimeoutId = setTimeout(() => {
        this.fetchFleetsPolling()
      }, 5 * 1000)
    },
    openAddAlertGroupModal () {
      this.openModal(this.addAlertGroupModal.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
  &-input{
    background-color: white;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    font-size: 16px;
    &::placeholder {
      color: #DDD;
    }
  }
}
</style>
