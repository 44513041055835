<template>
  <div class="banner-setting">
    <p class="intro-text">
      Use this section to manage the system banner that will be displayed to users. You can activate the banner for important announcements, such as scheduled maintenance or unexpected outages.
    </p>
    <div class="toggle-container">
      <label class="switch">
        <input type="checkbox" v-model="isActive" @change="handleToggleChange">
        <span class="slider round"></span>
      </label>
      <span class="toggle-label">
        {{ isActive ? 'Banner is active' : 'Banner is inactive' }}
      </span>
    </div>
    
    <div v-if="isActive" class="active-section">
      <textarea v-model="bannerText" placeholder="Enter your banner message here..." class="banner-textarea"></textarea>
      <p class="warning-message">
      Warning: Please use less than 320 characters to ensure the message is displayed correctly.
    </p>
      <p>{{ bannerText.length }} / 320 characters</p>
      <div class="recommendations">
        <p class="recommendation-title">Recommended messages:</p>
        <ul class="recommendation-list">
          <li><strong>Planned maintenance:</strong> “DFM will be unavailable for a scheduled maintenance from 2024-01-01 12:00 GMT+2 to 14:00 GMT+2”</li>
          <li><strong>Unexpected outage:</strong>  “DFM is currently experiencing technical issues. Teams are aware and working on it. Sorry for the inconvenience.”</li>
        </ul>
      </div>
      <div class="actions">
        <button :disabled="bannerText.length === 0" @click="saveBanner" class="save-button">Save</button>
        <button @click="cancelBanner" class="cancel-button">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,  // Initialize as inactive
      bannerText: '',   // Initialize banner text
    };
  },
  async created() {
    try {
      const response = await this.$requester.get('/bannerVisibility');
      if(response.data.status == 1 && this.$store.state.isCardVisible != "volontaryHide") {
        this.$store.commit('showCard', response.data.status)
        this.$store.commit('setBannerText', response.data.msg || '');
      }
      this.isActive = response.data.status === 1; // Correctly set active status
      this.bannerText = response.data.msg || ''; // Set banner text if present
    } catch (error) {
      console.error('Error fetching banner visibility:', error);
    }
  },
  methods: {
    async handleToggleChange() {
      try {
        if (!this.isActive) {
          // When deactivating, immediately update the banner status and hide it
          await this.$requester.post('/bannerVisibility', {
            msg: "",
            status: 0 // Deactivate
          });
          this.$store.commit('showCard', false); // Ensure the banner is hidden
        }
      } catch (error) {
        console.error('Error toggling banner:', error);
      }
    },
    
    async saveBanner() {
      try {
        const response = await this.$requester.post('/bannerVisibility', {
          msg: this.bannerText,
          status: 1 // Activate banner on save
        });

        this.isActive = response.data.status === 1;
        this.$store.commit('showCard', true); // Show the banner
        this.$store.commit('setBannerText', response.data.msg );
      } catch (error) {
        console.error('Error saving banner:', error);
      }
    },
    
    cancelBanner() {
      // Reset the state when canceled
      this.bannerText = '';
    }
  }
}
</script>



<style scoped>
.banner-setting {
  padding: 20px;
}

.intro-text {
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #333;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #005587;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  margin-left: 15px;
  font-size: 1rem;
  color: #00205b;
}

.active-section {
  margin-top: 20px;
}

.banner-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1.1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.recommendations {
  margin-bottom: 20px;
}

.recommendation-title {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 15px;
  margin-bottom: 10px;
}

.recommendation-list {
  padding-left: 20px;
  font-size: 1.1rem;
  color: #333;
}

.recommendation-list li {
  margin-bottom: 10px;
  font-weight: normal;
  list-style-type: disc;
}

.actions {
  display: flex;
  gap: 10px;
}

.actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  background-color: #005587;
  color: white;
  transition: background-color 0.3s ease;
}

.actions button:hover {
  background-color: #00406e;
}

.actions button:last-child {
  background-color: #f7644a;
}

.actions button:last-child:hover {
  background-color: #ff4d4d;
}
.save-button:disabled {
  background-color: #aaa;
  color: white;
  cursor: not-allowed;
}
</style>
