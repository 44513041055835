const getCertificateDate = (certDate) => {
  return certDate
}

export const DateService = {
  statusLabelDateValidation (certDate) {
    let certificateDate = getCertificateDate(certDate)
    let parsedCertificateDate = Date.parse(certificateDate)
    let parsedCurrentDate = Date.parse(this.dateNow())

    if (parsedCertificateDate < parsedCurrentDate) {
      return 'expired'
    } else if (parsedCertificateDate > parsedCurrentDate) {
      return 'valid'
    } else {
      return 'unchecked'
    }
  },
  dateNow () {
    let now = new Date()
    let year = now.getFullYear().toString()
    let month = (now.getMonth() + 1).toString()
    let date = now.getDate().toString()

    if (date.length === 1) { date = `0${date}` }
    if (month.length === 1) { month = `0${month}` }

    return `${year}-${month}-${date}`
  },
  getUTCDate (data) {
    let UTCDate = new Date(
      Date.UTC(
        data.getFullYear(),
        data.getMonth(),
        data.getDate(),
        data.getHours(),
        data.getMinutes()
      )
    )
    let CorrecTimeZoneDate = JSON.stringify(UTCDate)
    let dayValueFormat = CorrecTimeZoneDate
    let year = dayValueFormat.slice(1, 5)
    let month = dayValueFormat.slice(6, 8)
    let day = dayValueFormat.slice(9, 11)
    dayValueFormat = `${year}${month}${day}`

    return dayValueFormat
  },
  formatCalendarDate (data) {
    let dayValueFormat = data

    const year = dayValueFormat.slice(0, 4)
    const month = dayValueFormat.slice(5, 7)
    const day = dayValueFormat.slice(8, 11)
    dayValueFormat = `${year}${month}${day}`

    return dayValueFormat
  }
}
