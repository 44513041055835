<template>
  <div class="app-modal-body">
    <div class="message-wrapper has-text-centered">
      <p v-if="error === true" style="color: red" v-html="message"></p>
      <p v-else v-html="message"></p>
    </div>
    <!-- Buttons -->
    <div v-if="cancel === true" class="columns">
      <div class="column">
        <button class="btn cancel-clean" @click="closeModal">{{ $t('global.button_cancel') }}</button>
      </div>
      <div class="column">
        <button class="btn confirm" @click="confirm">{{ $t('global.button_ok') }}</button>
      </div>
    </div>

    <div v-else class="columns">
      <div v-if="warn === true" class="column has-text-centered"></div>
      <div v-else class="column has-text-centered">
        <button class="btn confirm" @click="confirm">{{ $t('global.button_ok') }}</button>
      </div>
    </div >  

    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ModalConfirm',
  props: {
    message: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      required: false
    },
    cancel: {
      type: Boolean,
      required: false
    },
    warn: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    confirm () {
      this.$emit('modalConfirmed')
    }
  }
}
</script>

<style scoped lang="scss">
.message-wrapper {
  margin: 30px 0;
}
.cancel-clean {
  float: right;
}
</style>
