<template>
  <section :style ="{background: linearGradientStyle}" class="columns action-bar dashboardHeader">
    <div class="column is-5">
      <input
        class="vuetify-input-fix dashboardHeader-input"
        type="text"
        name="filterData"
        v-model="filterData"
        :placeholder="$t(placeholder)"
        @keyup.enter="submitFilter"
      />
    </div>
    <div class="column buttons-column">
      <template v-if="selectedHeliCounter > 0">
        <button  v-if="isAHAdmin && selectedHeliCounter == 1 && !getSelectedDetailed[0].banned" class="btn hollow is-pulled-right block" @click="activateBannedModal()">
          {{ $t('global.button_ban_hc') }}
        </button>
        <button  v-if="isAHAdmin && selectedHeliCounter == 1 && getSelectedDetailed[0].banned" class="btn hollow is-pulled-right block" @click="activateUnbannedModal()">
          {{ $t('global.button_unban_hc') }}
        </button>
        <!-- Z2I81GIRE-162 -->
        <button  v-if="isAHAdmin" class="btn hollow is-pulled-right block" @click="activateRemoveHCModal()">
          {{ $t('global.button_remove_hc') }}
        </button>
        <button class="btn hollow is-pulled-right block" @click="activateRetrieveModal()">
          <span class="icon-download"></span>
          {{ $t('global.button_download_data') }}
        </button>
        <button v-if="isAdmin" class="btn hollow is-pulled-right block" @click="activateUploadModal()">
          <span class="icon-upload-data"></span>
          {{ $t('global.button_upload_data') }}
        </button>
      </template>
      <template v-else>
        <button
          v-if="isAHAdmin"
          class="btn hollow is-pulled-right block"
          @click="activateAddHCModal()"
        >{{ $t('global.button_add_hc') }}</button>
      </template>
    </div>
    <modal :title="addHCModal.title" :modalName="addHCModal.name" width="430px">
      <ModalAddHC />
    </modal>
    <modal :title="uploadFilesModal.title" :modalName="uploadFilesModal.name" width="460px">
      <ModalUploadFiles
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <modal :title="retrieveMultiModal.title" :modalName="retrieveMultiModal.name" width="774px">
      <ModalRetrieveMulti @responseSuccess="modalResponseSuccess" @responseError="modalResponseError"/>
    </modal>
     <!-- Z2I81GIRE-162 -->
    <modal :title="removeHCModal.title" :modalName="removeHCModal.name" width="460px">
      <ModalRemoveHC @responseSuccess="modalResponseSuccess" @responseError="modalResponseError"/>
    </modal>
    <modal :title="banHCModal.title" :modalName="banHCModal.name" width="460px">
      <ModalBanHC
        :getSelectedDetailed="banHCModal.props"
        @responseSuccess="modalResponseSuccess" 
        @responseError="modalResponseError"
      />
    </modal>
    <modal :title="unbanHCModal.title" :modalName="unbanHCModal.name" width="460px">
      <ModalUnbanHC 
        :getSelectedDetailed="unbanHCModal.props"
        @responseSuccess="modalResponseSuccess" 
        @responseError="modalResponseError"
      />
    </modal>
    <modal :modalName="confirmModal.name" width="430px">
      <ModalConfirm
        :message="confirmModal.message"
        :error="confirmModal.error"
        @modalConfirmed="closeModal()"
      />
    </modal>
</section>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import Modal from './modals/Modal'
import ModalAddHC from './modals/ModalAddHC'
import ModalUploadFiles from './modals/ModalUploadFiles'
import ModalConfirm from './modals/ModalConfirm'
import ModalRetrieveMulti from './modals/ModalRetrieveMulti'
import ModalRemoveHC from './modals/ModalRemoveHC'
import ModalBanHC from './modals/ModalBanHC'
import ModalUnbanHC from './modals/ModalUnbanHC'
import bus from '../event/bus';
export default {
  name: 'DashboardHeader',
  components: {
    Modal,
    ModalAddHC,
    ModalUploadFiles,
    ModalConfirm,
    ModalRetrieveMulti,
    ModalRemoveHC,
    ModalBanHC,
    ModalUnbanHC
  },
  data () {
    return {
      filterData: '',
      placeholder: 'global.search_placeholder',
      addHCModal: {
        name: 'addHC',
        title: 'actionModal.title'
      },
      uploadFilesModal: {
        name: 'ModalUploadFiles',
        title: 'uploadModal.title'
      },
      retrieveMultiModal: {
        name: 'ModalRetrieveMulti',
        title: 'retrieveMultiModal.title'
      },
      removeHCModal: {
        name: 'ModalRemoveHC',
        title: 'removeHCModal.title'
      },
      banHCModal: {
        name: 'ModalBanHC',
        title: 'banHCModal.title'
      },
      unbanHCModal: {
        name: 'ModalUnbanHC',
        title: 'unbanHCModal.title'
      },
      confirmModal: {
        name: 'confirmRetriveMulti',
        message: '',
        error: false
      }
    }
  },
  computed: {
    ...mapState(['userStore']),
    ...mapGetters('fleetStore', [
      'areAllFleetAdded',
      'isAnyActiveSelection',
      'selectedHeliCounter',
      'getSelectedDetailed'
    ]),
    ...mapGetters('userStore', ['isAdmin', 'isAHAdmin']),
    linearGradientStyle(){
      const startColor = process.env.VUE_APP_BACKGROUND_1
      const endColor = process.env.VUE_APP_BACKGROUND_2
      return `linear-gradient(to left, ${startColor}, ${endColor})`
    },

  },
  methods: {
    ...mapActions('fleetStore', ['setFleetQuery', 'setFetchedFleet']),
    ...mapActions('appStore', ['openModal', 'closeModal']),
    // Filter Helicopters
    submitFilter () {
      bus.$emit('submitFilter', {state: true});
      this.setFleetQuery(`?query=${this.filterData}`)
      //this.requsterProcesing = true
      //this.$emit("launch-filter-event", {state: true})
      this.$requester
        .get(`/helicopters?query=${this.filterData}`, {
          filter: this.filterData
        })
        .then(response => {
          this.setFetchedFleet(response.data.fleets)
        })
        .catch(function (error) {
          // TODO: make error handler
          console.log("fetch fleet error", error)
        })
     
    },
    activateAddHCModal () {
      this.openModal(this.addHCModal.name)
    },
    activateUploadModal () {
      let ifLessThanOneHcType = true
      let selectedHelicopers = this.getSelectedDetailed.map(h => {
        return {
          registration: h.registration,
          serial: h.serial,
          type: h.type
        }
      })
      if(this.selectedHeliCounter > 1){
        let hcTypeValueToVerify = selectedHelicopers[0].type
        for(let i=0 ; i < this.selectedHeliCounter; i++) {
          if (selectedHelicopers[i].type !== hcTypeValueToVerify){
            ifLessThanOneHcType = false         
          }
        }
      }
      if(ifLessThanOneHcType){
        this.openModal(this.uploadFilesModal.name)
      }else{
        let msg = this.$t('global.upload_modal_error')
        this.modalResponseError(msg)
      }
    },
    activateBannedModal() {
      this.openModal(this.banHCModal.name)
    },
    activateUnbannedModal() {
      this.openModal(this.unbanHCModal.name)
    },
    activateRetrieveModal () {
      this.openModal(this.retrieveMultiModal.name)
    },
    /* Z2I81GIRE-162 */
    activateRemoveHCModal () {
      this.openModal(this.removeHCModal.name)
    },
    modalResponseSuccess (msg) {
      this.confirmModal.error = false
      this.confirmModal.message = msg || this.confirmModal.message
      this.closeModal()
      this.openModal(this.confirmModal.name)
    },
    modalResponseError (errorMsg) {
      this.confirmModal.error = true
      this.confirmModal.message = errorMsg
      this.closeModal()
      this.openModal(this.confirmModal.name)
    }
  }
}
</script>

<style lang="scss">
.dashboardHeader {
  margin-left: 70%;
  &-input{
    background-color: #fff !important;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    font-size: 16px;
    &::placeholder {
      color: #DDD;
    }
  }
}
.containerHeader{
  display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px; /* Adjust the gap between elements as needed */

}

</style>
