<template>
    <div class="hc-data">
      <label class="label-data">
        <slot>
          {{label}}
        </slot>
      </label>
      <p :class="{color, 'transfer-holder': transferData}" class="data">
        <span class="transfer-icon" v-if="transferData">!</span>
        {{ data }}
      </p>
      <p >
        {{ data2 }}
      </p>
      <p >
        {{ data3 }}
      </p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      label: {
        type: String
      },
      data: {
        type: String
      },
      data2: {
        type: String
      },
      data3: {
        type: String
      },
      color: {
        type: String
      },
      transferData: {
        type: Boolean
      }
    }
  }
  </script>