<template>
  <keep-alive>
    <div class="general-view login">
      <div class="login-holder">
        <div class="logo-holder has-text-centered">
          <img src="../assets/images/Logo-Airbus-White.png" alt="Logo Airbus">
        </div>
        <div class="login-box">
          <form action>
            <div class="button-holder">
              <button
                class="btn accept login"
                @click.prevent="signIn"
                @keyup.enter="signIn"
              >{{ $t('login.sing_in') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { AuthService } from '../utils/authService'
export default {
  data () {
    return {
      errorMessage: false,
      formProcesing: false,
      dataLogin: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    signIn () {
      AuthService.getSignedIn().then(
        signIn => {
          if (!signIn) {
            console.log('sign in failed')
          }
        },
        err => {
          console.log(err)
        }
      )
    }
  }
}
</script>
