import Oidc from 'oidc-client'
import {jwtDecode} from 'jwt-decode'
import stores from '../store/index'
var mgr = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  authority: process.env.VUE_APP_COGNITO_URL,
  client_id: process.env.VUE_APP_COGNITO_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_URL,
  //silentRefreshRedirectUri:process.env.VUE_APP_URL,
  response_type: 'code',
  scope: 'openid profile',
  post_logout_redirect_uri: process.env.VUE_APP_URL + '/login',
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  includeIdTokenInSilentRenew: true,
  monitorSession: true,
  silent_redirect_uri: process.env.VUE_APP_URL,
  metadata: {
    token_endpoint: process.env.VUE_APP_COGNITO_URL + '/oauth2/token',
    issuer: process.env.VUE_APP_COGNITO_USER_POOL,
    authorization_endpoint: process.env.VUE_APP_COGNITO_URL + '/oauth2/authorize',
    userinfo_endpoint: process.env.VUE_APP_COGNITO_URL + '/oauth2/userInfo',
    jwks_uri: process.env.VUE_APP_COGNITO_USER_POOL + '/.well-known/jwks.json',
    end_session_endpoint: process.env.VUE_APP_URL + '/login'
  }
})

Oidc.Log.logger = console
Oidc.Log.level = Oidc.Log.INFO

mgr.events.addAccessTokenExpired(function () {
  // alert('Your session is expired. Please re-authenticate.')
  mgr.removeUser().then(function (resp) {
    stores.dispatch('userStore/logOut')
    console.log('user removed', resp)
    window.localStorage.clear()
    mgr.signoutRedirect().then(function (resp) {
      console.log('signed out', resp)
    }).catch(function (err) {
      console.log(err)
    })
  }).catch(function (err) {
    console.log(err)
  })
})

mgr.events.addAccessTokenExpiring(function () {
  AuthService.renewToken()
  stores.dispatch('userStore/loadTokenId')
  stores.dispatch('userStore/loadToken')
})

mgr.events.addUserLoaded(args => {
  mgr.signinSilentCallback()
     .catch((err) => {
      console.log(err)
    })
  mgr.getUser().then(user => {
    stores.dispatch('userStore/loadTokenId')
    stores.dispatch('userStore/loadToken')
  })
})

export const AuthService = {
  mgr: mgr,
  signIn () {
    mgr.signinRedirect().catch(function (err) {
      console.log(err)
    })
  },
  getAccessToken () {
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user !== null) {
          return resolve(user.access_token)
        } else {
          return resolve('')
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  },
  getIdToken () {
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user !== null) {
          return resolve(user.id_token)
        } else {
          return resolve('')
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  },
  signOut () {
    mgr.removeUser().then(function (resp) {
      stores.dispatch('userStore/logOut')
      console.log('user removed', resp)
      window.localStorage.clear()
      mgr.signoutRedirect().then(function (resp) {
        console.log('signed out', resp)
      }).catch(function (err) {
        console.log(err)
      })
    }).catch(function (err) {
      console.log(err)
    })
  },
  getSignedIn () {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        //mgr.signinSilent()
        mgr.startSilentRenew()
        if (user == null) {
          self.signIn()
          return resolve(false)
        } else {
          return resolve(true)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  },
  renewToken () {
    return new Promise((resolve, reject) => {
      mgr.signinSilent().then(function (user) {
        if (user !== null) {
          return resolve(user)
        } else {
          return resolve(null)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
},
  getRole () {
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user !== null) {
          var decriptedToken = jwtDecode(user.id_token)
          var token_roles = decriptedToken['custom:memberOf']
          if (token_roles != null) {
            var roles = token_roles.split(',')
            var userRole = null
            roles.forEach(role => {
              if (role.includes('GIRE')) {
                var roleRegexp = /usr-([\w\d-]*)/gm
                userRole = roleRegexp.exec(role)[1]
              }
            })
            return resolve(userRole)
          } else {
            return resolve(null)
          }
        } else {
          return resolve(null)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  },
  getEmail () {
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user !== null) {
          // return resolve('MaintenanceCenter-adm')

          var decriptedToken = jwtDecode(user.id_token)
          var email = decriptedToken['email']
          return resolve(email)
        } else {
          return resolve(null)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  },
  getUserName () {
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user !== null) {
          // return resolve('MaintenanceCenter-adm')
          var decriptedToken = jwtDecode(user.id_token)
          var name = decriptedToken['cognito:username']
          return resolve(name)
        } else {
          return resolve(null)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      })
    })
  }
}
