import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '../views/Dashboard'
import Configuration from '../views/Configuration'
import Management from '../views/Management'
import Transfer from '../views/Transfer'
import Login from '../views/Login'
import Profile from '../views/Profile'
import Alert from '../views/Alert'
import ApiService from '../utils/apiService'

import { AuthService } from '../utils/authService'
import userStore from '../store/modules/userStore'
import stores from '../store/index'

import { ROUTERS } from './routerConfiguration'

Vue.use(Router)
const routerInstance = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Dashboard,
      ...ROUTERS.dashboard
    },
    {
      path: '/profile',
      component: Profile,
      ...ROUTERS.profile
    },
    {
      path: '/configuration',
      component: Configuration,
      ...ROUTERS.configuration,
      beforeEnter: (to, from, next) => {
        if (userStore.state.role !== 'Internal-adm') {
          return next({ name: 'dashboard' })
        }
        next()
      }
    },
    {
      path: '/transfer',
      component: Transfer,
      ...ROUTERS.transfer
    },
    {
      path: '/management',
      component: Management,
      ...ROUTERS.management
    },
    {
      path: '/alert',
      component: Alert,
      ...ROUTERS.alert,
      beforeEnter: (to, from, next) => {
        if (userStore.state.role === 'user') {
          return next({ name: 'dashboard' })
        }
        next()
      }
    },
    {
      path: '/login',
      component: Login,
      ...ROUTERS.login
    }
  ]
})

routerInstance.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
  } else if (to.fullPath.includes('code')) {
    AuthService.mgr.signinRedirectCallback().then(async function (user) {
      await loadAsync()
      next({ name: 'dashboard' })
    }).catch(function (err) {
      console.log(err)
      AuthService.signOut()
      next({ name: 'login' })
    })
  } else {
    AuthService.getSignedIn().then(
      async function (signIn) {
        if (!signIn) {
          return next({ name: 'login' })
        } else {
          await loadAsync()
          next()
          AuthService.getRole().then(function (role) {
            if ((role === 'Internal-adm') && !( userStore.state.user_name.startsWith('a') || userStore.state.user_name.startsWith('g') || userStore.state.user_name.startsWith('x') ) ) {
                alert('Invalid Role for a z account')
                AuthService.signOut()
            } else {
              next()
            }
            }).catch(function (err) {
              console.log(err)
            })

        }
      },
    err => {
      console.log(err)
    })
  }
})

function ip () {
  return new Promise((resolve, reject) => {
    ApiService
      .get('/ip').then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

async function loadAsync () {
  return new Promise( async function (resolve, reject) {
    await stores.dispatch('userStore/loadToken')
    await stores.dispatch('userStore/loadRole')
    await stores.dispatch('userStore/loadEmail')
    await stores.dispatch('userStore/loadUserName')
    await stores.dispatch('userStore/loadTokenId')
    await stores.dispatch('userStore/loadOrganization')
    resolve(true)
  })
}

export default routerInstance
