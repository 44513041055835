<template>
  <div class="app-modal-addHC-body">
    <PageLoading v-if="loading" />
    <div v-else class="content-wrapper">
      <div class="input-basic">
        <label>{{ $t('actionModal.type_list_unmanaged_hc') }}</label>
        <select v-model="selectedType" @change="changeList()">
          <option value="default">{{ $t('actionModal.list_unmanaged_hc_choose_message') }}</option>
          <option v-for="type in uniqueTypes" :key="type" :value="type">{{ type }}</option>
        </select>
        <label>{{ $t('actionModal.serial_list_unmanaged_hc') }}</label>
        <div class="custom-dropdown">
          <v-autocomplete
            v-model="selected"
            :items="filteredHc"
            item-text="displayValue" 
            item-value="hc" 
            return-object
            :disabled="selectedType === ''"
            clearable
            @change="handleSelection"
          ></v-autocomplete>
        </div>
      </div>

      <!-- Buttons -->
      <div class="columns is-centered buttons-wrapper">
        <table  aria-label="Signal Status Table">
          <tr>
            <th id="col"></th>
            <th id="col"></th>
            <th id="col"></th>
          </tr>
          <tr>
            <td id="row">
              <a
                @click="closeModal()"
                class="button btn column is-5 cancel-clean"
              >{{ $t('global.button_cancel') }}
              </a>
            </td>
            <td id="row">
              <a
                @click="clearInput()"
                class="button btn column is-5 cancel-clean"
              >{{ $t('global.button_clear') }}</a>
            </td>
            <td id="row">
              <a
                :disabled="disabled"
                @click="getModalUpload()"
                class="button btn validationBtn column is-5"
              >{{ $t('global.button_add_hc') }}</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageLoading from '../../views/PageLoading'

export default {
  components: {
    PageLoading
  },
  mounted () {
    this.loadUnmanagedHc()
  },
  data () {
    return {
      newHelicopter: {
        HCRegistrationNumber: '',
        hcSerialNumber: '',
        hcType: '',
        hcFleet: '',
      },
      inputStatus: [],
      errMsg: '',
      requestProcessing: false,
      selected: '#',
      unmanagedHc: [],
      disabled: true,
      loading: true,
      selectedType: 'default', // Initialize selectedType with the default value
      selectedModel: null,
      searchQuery: '',
    }
  },
  computed: {
    sortedUnmanagedHc() {
      function compare(a, b) {
        if (a.serial.toLowerCase() < b.serial.toLowerCase()) return -1;
        if (a.serial.toLowerCase() > b.serial.toLowerCase()) return 1;
        return 0;
      }
      return this.unmanagedHc.sort(compare);
    },
    uniqueTypes() {
      return [...new Set(this.sortedUnmanagedHc.map(hc => hc.type))];
    },
    uniqueModels() {
      return [...new Set(this.sortedUnmanagedHc.map(hc => hc.model))];
    },
    filteredList() {
      let filteredHc = this.sortedUnmanagedHc;
      if (this.selectedType && this.selectedType !== 'default') {
        filteredHc = filteredHc.filter(hc => hc.type === this.selectedType);
      }
      if (this.selectedModel) {
        filteredHc = filteredHc.filter(hc => hc.model === this.selectedModel);
      }
      return filteredHc;
    },
    filteredHc() {
      if (!this.selectedType || this.selectedType === 'default') return [];
      return this.sortedUnmanagedHc
        .filter(hc =>
          hc.type === this.selectedType &&
          (hc.serial.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          hc.registration.toLowerCase().includes(this.searchQuery.toLowerCase()))
        )
        .map(hc => ({
          ...hc,
          displayValue: `${hc.serial} / ${hc.registration}` // Add a displayValue property
        }));
    },
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    isAddHCBtnDisabled () {
      return !(
        (this.newHelicopter.HCRegistrationNumber.length >= 3) ||
        (this.selected !== '#') ||
        (this.newHelicopter.hcType.length >= 3 &&
        this.newHelicopter.hcSerialNumber.length >= 3)
      )
    },
    getModalUpload() {
      this.$requester
        .post('/helicopters', {
          serial: this.newHelicopter.hcSerialNumber,
          type: this.newHelicopter.hcType
        })
        .then(response => {
          const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

          this.$router.go();
        })
        .catch(error => {
          console.log(error);
        });
    },

    loadUnmanagedHc() {
      this.$requester
        .get('/helicopters', { params: { managed: false } })
        .then(response => {
          const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

          responseData.fleets.forEach(fleet =>
            fleet.aircrafts.forEach(hc => this.unmanagedHc.push(hc))
          );
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    getParams () {
      var selectedType = this.selectedType
      var selectedSerial = this.newHelicopter.hcSerialNumber
      if (this.selected !== '#') {
        selectedType = this.selected.type
        selectedSerial = this.selected.serial
      }
      return {
        registration: this.newHelicopter.HCRegistrationNumber,
        type: selectedType,
        serial: selectedSerial
      }
    },
    clearInput () {
      this.newHelicopter.hcSerialNumber = ''
      this.newHelicopter.hcType = ''
      this.newHelicopter.hcFleet = ''
      this.newHelicopter.HCRegistrationNumber = ''
      this.errMsg = ''
      this.selected = '#'
    },
    changeList () {
      this.autoFillHCDatq()
    },
    autoFillHCDatq () {
      this.disabled = true
      this.requestProcessing = true
      this.$requester
          .get(
            '/autoComplete', { params: this.getParams() }
          )
          .then(response => {
            if (response.data.msg) {
              this.clearInput()
              this.errMsg = this.$t(`actionModal.msg_${response.data.msg}`)
            } else {
              this.newHelicopter.hcSerialNumber = response.data.serial
              this.newHelicopter.hcType = response.data.type
              this.newHelicopter.hcFleet = response.data.fleet
              this.newHelicopter.HCRegistrationNumber = response.data.registration
              this.errMsg = ''
              this.disabled = false
            }
            this.requestProcessing = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    handleSelection(value) {
      this.selected = value;
      // Run changeList method
      this.changeList();
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled-select {
  pointer-events: none;
  background-color: #f5f5f5; /* Change background color to visually indicate it's disabled */
  opacity: 0.5; /* Adjust opacity to visually indicate it's disabled */
}
.app-modal-addHC-body {
  display: flex;
  flex-direction: column;
  .content-wrapper {
    margin: 0 auto;
  }
  .input-basic {
    margin-bottom: 20px;
    label {
      margin-bottom: 5px;
      font-weight: normal;
    }
    input {
      width: 243px;
      &.reg-num {
        border: 1px solid #0082d5;
      }
      &:disabled {
        border: none;
        background-color: rgba(154, 164, 183, 0.2);
        color: #647491;
      }
    }
    select {
      width: 243px;
      border: 1px solid #0082d5;
      margin-bottom: 5px;
      font-weight: normal;
      height: 35px;
      font-size: 15px;
      padding-left: 10px;
    }
    p {
      width: 243px;
    }
  }
  .cancel-addHC {
    display: block;
    color: #fff;
    background-color: #0082d5;
    padding: 5px 20px;
    min-width: auto;
    margin: 0 !important;
  }
  .cancel-addHC {
    border: none;
    padding: 5px 20px;
    min-width: auto;
    color: #647491;
  }
  .btn-status {
    display: block;
    font-size: 12px;
    color: #647491;
  }
  .columns {
    margin: 0;
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .column {
      padding: 0;
    }
  }
  .buttons-wrapper {
    margin-top: 50px;
  }
}
</style>
