<template>
  <div>
    <PageLoading v-if="requsterProcesing" />
    <div class="modal-retrieve" :class="{'hide-content-on-loading': this.requsterProcesing}">
      <!-- List registration -->
      <div class="modal-retrieve__registrations-list">
        <label>{{ $t('retrieveMultiModal.sub_title') }}</label>
        <div class="columns is-multiline is-gapless retrive-multi__registrations">
          <div
            class="column is-3"
            v-for="heli in getSelectedDetailed"
            :key="heli.registration"
          >{{heli.registration}}</div>
        </div>
      </div>
      <div class="modal-retrieve__content">
        <!-- File types -->
        <div class="columns">
          <span>{{ $t('sessionFilters.file_types') }}</span>
        </div>
        <div class="columns">
          <ModalRetrieveFileTypes ref="modalRetrieveFileTypes" :heli="getHelico()" :selectedHelis="this.getSelectedDetailed" />
        </div>
        <!-- Fitlers -->
        <div class="columns">
          <label class="title-label">{{ $t('sessionFilters.filter') }}</label>
        </div>
        <ModalRetrieveFilterBy v-if="this.getSelectedDetailed.length >1" ref="modalRetrieveFilterBy" :multi="this.getSelectedDetailed.length >1" />
        <ModalRetrieveFilterBy v-else ref="modalRetrieveFilterBy" :heli="getHelico()" :multi="this.getSelectedDetailed.length >1" />
      </div>
      <!-- Buttons -->
      <div class="columns is-centered modal-retrieve__buttons-wrapper">
        <a @click="closeModal" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
        <a class="button btn validationBtn" @click="retrieveFiles()">{{ $t('global.button_retrieve') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import convertZipResponse from '../../utils/convertZipResponse'

import PageLoading from '../../views/PageLoading'
import ModalRetrieveFilterBy from './ModalRetrieveFilterBy'
import ModalRetrieveFileTypes from './ModalRetrieveFileTypes'

import { DateService } from '../../utils/dateService'

export default {
  name: 'ModalRetrieveMulti',
  components: {
    ModalRetrieveFilterBy,
    ModalRetrieveFileTypes,
    PageLoading
  },
  data () {
    return {
      requsterProcesing: false,
      modal: false
    }
  },
  computed: {
    ...mapGetters('fleetStore', ['getSelectedDetailed'])
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    retrieveFiles () {
      this.requsterProcesing = true
      let formData = new FormData()
      let dataFromFilters = this.$refs.modalRetrieveFilterBy.getFiltersData()
      let dataFromTypes = this.$refs.modalRetrieveFileTypes.getTypesData()
      let selectedHelicopers = this.getSelectedDetailed.map(h => {
        return {
          registration: h.registration,
          serial: h.serial,
          type: h.type
        }
      })

      formData.append(
        'selected_helicopters',
        JSON.stringify(selectedHelicopers)
      )
      formData.append('selected_files', dataFromTypes)
      for (let filter in dataFromFilters) {
        formData.append(filter, dataFromFilters[filter])
      }
      this.$requester
        .post('/download', formData, {
        })
        .then((response) => {
          this.loading = false
          this.requsterProcesing = false
          const parts = response.data.msg.split("/");
          const filename = parts[parts.length - 1];
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href =  response.data.msg;
          a.download = filename
          document.body.appendChild(a);
          let incompletedSessionNumber = response.headers.incompletedsession
          let SelectedSession = response.headers.selectedsession
          let inCompletedSessionMsg = this.$t('global.partial_sessions').replace("x1", incompletedSessionNumber) 
          if(incompletedSessionNumber != SelectedSession){
            inCompletedSessionMsg = inCompletedSessionMsg.replace("x2", SelectedSession)
          } else {
            inCompletedSessionMsg = this.$t('global.partial_sessions2')
          }
          let msg = (incompletedSessionNumber == 0) ? 
          this.$t('global.download_success') 
          : inCompletedSessionMsg;
          a.click();
          this.$emit('responseSuccess', msg)
          const dtrum = window.dtrum; 
          dtrum.enterAction('Retrieve Event')
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 404) {
              let msg = this.$t('global.no_files_to_import')
              this.$emit('responseError', msg)
            } else if (error.response.status === 500) {
              // missing field
              let errMsg = this.$t('errorMsg.err')
              this.$emit('responseError', `${errMsg}`)
            } else {
              let msg = this.$t('global.nginx_timeout')
              this.$emit('responseError', msg)
            }
          }
        })
        .finally(() => {
          this.requsterProcesing = false
        })
    },
    getHelico () {
      let helico = {}
      this.getSelectedDetailed.map(h => {
        helico = {
          registration: h.registration,
          serial: h.serial,
          type: h.type
        }
      })
      return helico
    }
  }
}
</script>

<style lang="scss">
.retrive-multi__registrations {
  margin: 20px 0 !important;
  div {
    font-size: 14px;
  }
}
</style>
