<template>
  <div class="global-card" :class="{ hidden: !isCardVisible || isCardVisible === 'volontaryHide' }">
    <p class="centered-text">{{ bannerText }}</p>
    <span class="close-btn" @click="closeCard">&times;</span>
  </div>
</template>

<script>
export default {
  computed: {
    isCardVisible () {
      return this.$store.state.isCardVisible
    },
    bannerText () {
      return this.$store.state.bannerText
    }
  },
  methods: {
    closeCard () {
      this.$store.commit('showCard', 'volontaryHide')
    }
  }
}
</script>

<style scoped>
.global-card {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 20px;
  background-color: rgb(255, 203, 5);
  color: black !important;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.centered-text {
  margin: 0;
  text-align: center;
  color: black !important;
  flex: 1;
  font-size: 1rem; /* Increased font size */
}

.close-btn {
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1;
  padding-left: 15px;
  color: black;
}

.global-card p {
  margin: 0;
}

.global-card {
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hidden {
  display: none;
}
</style>
