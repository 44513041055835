<template>
    <div>
      <table  aria-label="Signal Status Table" style="width:100%;">
        <thead>
          <tr>
            <th id="col"></th>
            <th id="col"></th>
            <th></th>
          </tr>
          <tr>
            <td id="row" style="width:30%; vertical-align:middle; padding-left: 10px">{{alert.registration_nb}} : {{alert.serial}}/{{alert.type}}</td>
            <td id="row" style="width:70%; padding-top:5px;">
              <v-tooltip slot="append" bottom color="rgb(227, 0, 43)">
                <svg slot="activator" class="alert__button" @click="openDeleteAlertModal()" fill="#005587" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                  <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
                <span>{{ $t('alert.tooltip_delete_alert') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </thead>
      </table>
      <modal :modalName="deleteAlertModal.name" width="430px">
        <ModalConfirm
          :message="deleteAlertModal.message"
          :cancel=true
          @modalConfirmed="deleteAlert()"
        />
      </modal>
    </div>
  </template>

<script>
import { mapActions } from 'vuex'

import Modal from '../components/modals/Modal'
import ModalConfirm from '../components/modals/ModalConfirm'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {
    Modal,
    ModalConfirm
  },
  props: {
    alert: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      deleteAlertModal: {
        message: this.$t('alert.delete_alert_message'),
        name: 'deleteAlertModal' + this.alert.id
      }
    }
  },
  methods: {
    ...mapActions('appStore', ['openModal', 'closeModal']),
    deleteAlert () {
      let formData = new FormData()
      formData.append('alert', JSON.stringify(this.alert))
      this.$requester
        .post(`/delete_alert`, formData)
        .then(response => {
          this.$emit('reload')
          this.closeModal()
        })
        .catch(error => {
          console.log(error)
        })
    },
    openDeleteAlertModal (alert) {
      this.openModal(this.deleteAlertModal.name)
    }
  }
}
</script>