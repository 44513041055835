<template>
  <div>
    <header class="content-header">
      <div class="checkbox-holder">
        <input
          type="checkbox"
          :id="fleet.name"
          :name="fleet.name"
          :value="fleet.name"
          v-model="localModel"
        >
        <label :for="fleet.name" :style="{ marginTop: isFirstIteration ? '100px' : '0' }">
        <h1 class="fleet-name">{{ fleet.name }}</h1>
      </label>
      </div>
    </header>

    <!-- Block View of helicopter cards -->
    <div class="columns" v-if="fleetTableView === true">
      <ul class="column is-full-tablet is-half-desktop">
        <li v-for="aircraft in even(fleet.aircrafts)" :key="aircraft.id">
          <HeliCard :helicopter="aircraft" :fleetName="fleet.name" mode="block" class="mode-block"/>
        </li>
      </ul>
      <ul class="column is-full-tablet is-half-desktop">
        <li v-for="aircraft in odd(fleet.aircrafts)" :key="aircraft.id">
          <HeliCard :helicopter="aircraft" :fleetName="fleet.name" mode="block" class="mode-block"/>
        </li>
      </ul>
    </div>

    <!-- Table View of helicopter cards -->
    <div class="columns" v-else>
      <ul class="column">
        <li v-for="aircraft in fleet.aircrafts" :key="aircraft.id">
          <HeliCard :helicopter="aircraft" :fleetName="fleet.name" mode="table" class="mode-table"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import HeliCard from './HeliCard/HeliCard'

export default {
  name: 'Fleet',
  components: { HeliCard },
  props: {
    fleet: {
      type: Object,
      required: true
    },
    isFirstIteration: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('fleetStore', ['isWholeFleetAdded']),
    ...mapState('appStore', ['fleetTableView']),
    localModel: {
      get () {
        return this.isWholeFleetAdded(this.fleet.name)
      },
      set (newValue) {
        if (newValue) {
          this.addAllMachinesFromFleet(this.fleet.name)
        } else {
          this.removeAllMachinesFromFleet(this.fleet.name)
        }
      }
        },


  },

  methods: {
    ...mapActions('fleetStore', [
      'removeAllMachinesFromFleet',
      'addAllMachinesFromFleet'
    ]),
    even (fleets) {
      return fleets.filter((fleet, index) => index % 2 === 0);
    },
    odd (fleets) {
      return fleets.filter((fleet, index) => index % 2 !== 0);
    }
  }
}
</script>
