<template>
  <portal to="modalPortal" v-if="modalName === activeModal">
    <div class="modal" :class="{'is-active': modalName === activeModal}" :id="modalName">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content" :style="{overflow: 'visible'}">
        <div class="modal-card" :style="{width: width, height: height, overflow: 'visible'}">
          <header v-if="title">
            <div class="app-modal-header">
              <h2 class="has-text-centered">{{ $t(title) }}</h2>
            </div>
          </header>
          <section class="modal-card-body">
            <!-- Content ... -->
            <slot></slot>
          </section>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeModal"/>
    </div>
  </portal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Modal',
  props: {
    modalName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    width: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapState('appStore', ['modalComponent']),
    activeModal () {
      return this.modalComponent
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal'])
  }
}
</script>
