import { i18n } from '@/lang/i18n'
import { LOCAL_STORAGE } from '@/utils/constants/localStorageItems'

const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING'
const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
const CHANGE_FLEET_LAYOUT = 'CHANGE_FLEET_LAYOUT'
const OPEN_MODAL = 'OPEN_MODAL'
const CLOSE_MODAL = 'CLOSE_MODAL'
const BROWSER_IS_IE = 'BROWSER_IS_IE'
const BROWSER_IS_EDGE = 'BROWSER_IS_EDGE'

const state = {
  isGlobalLoading: false,
  fleetTableView: true,
  lang: localStorage.getItem(LOCAL_STORAGE.LANG) || 'en',
  modalComponent: '',
  isIE: false,
  isEdge: false,
  showDeliveredFiles: false
}

const actions = {
  startGlobalLoading ({ commit }) {
    commit(SET_GLOBAL_LOADING, true)
  },
  stoptGlobalLoading ({ commit }) {
    commit(SET_GLOBAL_LOADING, false)
  },
  changeLanguage ({ commit }, payload) {
    commit(CHANGE_LANGUAGE, payload)
  },
  openModal ({ commit }, name) {
    commit(OPEN_MODAL, name)
  },
  closeModal ({ commit }) {
    commit(CLOSE_MODAL)
  },
  changeFleetLayout ({ commit }, payload) {
    commit(CHANGE_FLEET_LAYOUT, payload)
  },
  confirmIE ({ commit }) {
    commit(BROWSER_IS_IE)
  },
  confirmEdge ({ commit }) {
    commit(BROWSER_IS_EDGE)
  }
}

const mutations = {
  [SET_GLOBAL_LOADING]: (state, data) => {
    state.isGlobalLoading = data
  },
  [CHANGE_LANGUAGE]: (state, langName) => {
    state.lang = langName
    localStorage.setItem(LOCAL_STORAGE.LANG, langName)
    i18n.locale = langName
  },
  [CHANGE_FLEET_LAYOUT]: (state, data) => {
    state.fleetTableView = data
  },
  [OPEN_MODAL]: (state, name) => {
    state.modalComponent = name
  },
  [CLOSE_MODAL]: () => {
    state.modalComponent = ''
  },
  [BROWSER_IS_IE]: (state) => {
    state.isIE = true
  },
  [BROWSER_IS_EDGE]: (state) => {
    state.isEdge = true
  }
}

const getters = {
  isLoadingStarted: state => {
    return state.isGlobalLoading
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
