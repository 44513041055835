<template>
  <div>
    <PageLoading v-if="requsterProcesing" />
    <div
      class="app-modal-upload-body"
      :class="{ 'hide-content-on-loading': this.requsterProcesing }"
    >
      <div class="shadow param_bloc" id="file_size">
        <div id="title_param">
          {{ $t("updateUploadParameterModal.input_label_size") }}
        </div>
        <div id="content_param">
          <table  aria-label="Signal Status Table" class="content-array">
            <thead>
              <tr>
                <th id="col">
                  <label style="font-size: 14px">
                    <input
                      style="font-size: 17px; height: 35px; padding-left: 5px"
                      class="shadow"
                      v-model="HMIvalue4"
                      :placeholder="this.$t('updateUploadParameterModal.hmi_4_placeholder')"
                      :searchable="false"
                      :reset-after="false"
                      :show-labels="false"
                    />
                  </label>
                </th>
                <th id="col">
                  <a :disabled="isUpdateBtnDisabled" @click="isUpdateBtnDisabled ? '' : updateSize()" class="button btn update-param">
                    {{ $t("global.update_parameter") }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="row" colspan="2">
                  <div class="config-data">
                    <label class="label-data">
                      <slot>{{ $t("updateUploadParameterModal.file_size") + size + " (Mo)" }}</slot>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td id="row" colspan="2">
                  <div class="config-data">
                    <label class="label-data">
                      <slot>{{ $t("updateUploadParameterModal.max_file_size") }}</slot>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="shadow param_bloc" id="file_type">
        <div id="title_param">
          {{ $t("updateUploadParameterModal.file_type") }}
        </div>
        <div id="content_param">
          <table  aria-label="Signal Status Table" class="content-array">
            <thead>
                <tr>
                    <th style="font-weight: 450; color: #002055;">{{ $t("uploadModal.hmi_1")}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td id="row">
                    </td>
                    <td id="row" colspan="2">
                        <span>
                            <multiselect
                                style="min-width: 200px"
                                class="shadow"
                                v-model="HMIvalue1"
                                track-by="name"
                                label="name"
                                :placeholder="$t('uploadModal.hmi_1_placeholder')"
                                :options="fileTypes"
                                :searchable="false"
                                :reset-after="false"
                                :show-labels="false"
                                :allow-empty="false"
                                @input="HMIvalue2 = ''"
                            ></multiselect>
                        </span>
                    </td>
                </tr>
                <tr v-if="HMIvalue1">
                    <td id="row">
                        <label style="font-weight: 450">{{
                            $t("uploadModal.hmi_2")
                        }}</label>
                    </td>
                    <td id="row" colspan="2">
                        <span>
                            <Multiselect
                                style="min-width: 200px"
                                class="shadow"
                                v-model="HMIvalue2"
                                :placeholder="this.$t('uploadModal.hmi_2_placeholder')"
                                :options="HMIvalue1.types"
                                :searchable="false"
                                :reset-after="false"
                                :show-labels="false"
                                :allow-empty="false"
                            />
                        </span>
                    </td>
                </tr>
                <tr v-if="HMIvalue2">
                    <td id="row" colspan="3">
                        <ul>
                            <li
                                v-for="hc_type in heli_types"
                                :key="hc_type"
                                class="modal-retrieve__checkbox-wrapper"
                            >
                                <div class="modal-retrieve__checkbox checkbox-holder block">
                                    <input
                                        v-model="selected_heli_types"
                                        :id="hc_type"
                                        type="checkbox"
                                        :name="hc_type"
                                        :value="hc_type"
                                    />
                                    <label :for="hc_type">{{ hc_type }}</label>
                                </div>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr v-if="HMIvalue2">
                    <td id="row">
                        <label style="font-weight: 450">{{
                            $t("updateUploadParameterModal.input_label_extension")
                        }}</label>
                    </td>
                    <td id="row">
                        <label style="font-size: 14px; width: 300px">
                            <input
                                v-model="HMIvalue3"
                                style="font-size: 17px; height: 35px; padding-left: 5px"
                                class="shadow"
                                :placeholder="
                                    this.$t('updateUploadParameterModal.hmi_3_placeholder')
                                "
                                :searchable="false"
                                :reset-after="false"
                                :show-labels="false"
                                :allow-empty="false"
                            />
                        </label>
                    </td>
                    <td id="row">
                        <div>
                            <a
                                :disabled="isAddRomoveBtnDisabled"
                                @click="isAddRomoveBtnDisabled ? '' : addParameter()"
                                class="button btn add-param"
                            >{{ $t("global.add_parameter") }}</a>
                            <a
                                :disabled="isAddRomoveBtnDisabled"
                                @click="isAddRomoveBtnDisabled ? '' : removeParameter()"
                                class="button btn remove-param"
                            >{{ $t("global.remove_parameter") }}</a>
                        </div>
                    </td>
                </tr>
                <tr v-for="hc_type in heli_types" :key="hc_type.id">
                    <td id="row" colspan="3">
                        <table  aria-label="Signal Status Table" class="info-config-data">
                            <thead>
                                <tr>
                                    <th style="padding-top: 10px; padding-bottom: 10px; color: #002055;">{{ hc_type }}</th>
                                    <th id="col"></th>
                                    <th id="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td id="row">
                                        <div class="config-data">
                                            <label class="label-data">
                                                <slot>{{ $t("updateUploadParameterModal.file_type") }}</slot>
                                            </label>
                                        </div>
                                    </td>
                                    <td id="row">
                                        <div class="config-data">
                                            <label class="label-data">
                                                <slot>{{ $t("updateUploadParameterModal.target_application") }}</slot>
                                            </label>
                                        </div>
                                    </td>
                                    <td id="row">
                                        <div class="config-data">
                                            <label class="label-data">
                                                <slot>{{ $t("updateUploadParameterModal.extension") }}</slot>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="extension in extensions" :key="extension.id" v-if="extension.hc_type == hc_type">
                                    <td id="row">
                                        <div class="config-data">
                                            <label class="label-data">
                                                <slot>{{ extension.file_type }}</slot>
                                            </label>
                                        </div>
                                    </td>
                                    <td id="row">
                                        <div class="config-data">
                                            <label class="label-data">
                                                <slot>{{ extension.target_application }}</slot>
                                            </label>
                                        </div>
                                    </td>
                                    <td id="row">
                                        <div class="config-data">
                                            <label class="label-data">
                                                <slot>{{ extension.extension.toLowerCase() }}</slot>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
//import { fs } from 'fs'
import { HMI_FILES } from "../utils/constants/hmiFiles";
import PageLoading from "../views/PageLoading";
import { HELI_TYPES } from "../utils/constants/uploadSettingsHcTypes.js";

export default {
  name: "ModalUpdateUploadParameter",
  beforeMount() {
    this.getSize();
    this.getExtension();
  },
  components: {
    PageLoading,
  },
  data() {
    return {
      fileTypes: HMI_FILES,
      HMIvalue1: "",
      HMIvalue2: "",
      HMIvalue3: "",
      HMIvalue4: "",
      requsterProcesing: false,
      extensions: [],
      heli_types: HELI_TYPES,
      selected_heli_types: HELI_TYPES.slice(0),
      /*extensionsBeforeWrite: { hc_type: "" , 
      extensions: [{
        extension: "",
        file_type : "",
        target_application : ""
      }
      ] },
      extensionFromJson : upload_settings_matrix.extensions,
      hcTypeFromJson : upload_settings_matrix.hc_type,*/
      size: 0,
    };
  },
  computed: {
    isAddRomoveBtnDisabled() {
      return (
        this.HMIvalue1 === "" || this.HMIvalue2 === "" || this.HMIvalue3 === ""
      );
    },
    isUpdateBtnDisabled() {
      return this.HMIvalue4 === "";
    },
  },
  methods: {
    ...mapActions("appStore", ["closeModal"]),
    updateSize() {
      this.requsterProcesing = true;

      let formData = new FormData();
      let checkInput = this.HMIvalue4.trim().replace(/^[0-9]*$/, "");

      if (checkInput !== "") {
        this.$emit("responseError", "Number only !");
      } else {
        let newSize = parseInt(this.HMIvalue4.trim(), 10);
        formData.append("size", newSize);

        this.$requester
          .post("/files/configure/size", formData)
          .then((response) => {
            if (response) {
              this.getSize();
            }
          })
          .catch((error) => {
            if (error.response) {
              this.requsterProcesing = false;
              let errMsg = error.response.data.msg;
              this.$emit("responseError", `${errMsg}`);
            }
          });
      }
    },
    getSize() {
      this.requsterProcesing = true;

      this.$requester
        .get("/files/configure/size/authorized")
        .then((response) => {
          //this.size = response.data.size
          this.size = response.data;
          this.requsterProcesing = false;
        })
        .catch((error) => {
          console.log(error.response.data.msg);
          this.requsterProcesing = false;
        });
    },
    addParameter() {
      this.requsterProcesing = true;

      let formData = new FormData();

      if (this.HMIvalue1.name) {
        formData.append("file_type", this.HMIvalue1.name);
      }
      if (this.HMIvalue2) {
        formData.append(
          "target_application",
          this.HMIvalue2.replace(/\s/g, "")
        );
      }
      if (this.HMIvalue3) {
        formData.append("extension", this.HMIvalue3.trim());
      }

      formData.append("selected_heli_types", this.selected_heli_types);
      formData.append("action", 1);

      this.$requester
        .post("/files/configure/extension", formData)
        .then((response) => {
          if (response) {
            this.getExtension();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.requsterProcesing = false;
            let errMsg = error.response.data.msg;
            this.$emit("responseError", `${errMsg}`);
          }
        });
    },
    removeParameter() {
      this.requsterProcesing = true;

      let formData = new FormData();

      if (this.HMIvalue1.name) {
        formData.append("file_type", this.HMIvalue1.name);
      }
      if (this.HMIvalue2) {
        formData.append(
          "target_application",
          this.HMIvalue2.replace(/\s/g, "")
        );
      }
      if (this.HMIvalue3) {
        formData.append("extension", this.HMIvalue3.trim());
      }

      formData.append("selected_heli_types", this.selected_heli_types);
      formData.append("action", 2);

      this.$requester
        .post("/files/configure/extension", formData)
        .then((response) => {
          if (response) {
            this.getExtension();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.requsterProcesing = false;
            let errMsg = error.response.data.msg;
            this.$emit("responseError", `${errMsg}`);
          }
        });
    },
    getExtension() {
      this.requsterProcesing = true;
      this.$requester
        .get("/files/configure/extension/authorized")
        .then((response) => {
          this.extensions = response.data.extensions;
          this.requsterProcesing = false;
        })
        .catch((error) => {
          console.log(error.response.data.msg);
          this.requsterProcesing = false;
        });
    },
  },
};
</script>

<style lang="scss">
.param_bloc {
  //margin-top: 100px;
  margin-bottom: 60px;
  width: 90%;
}
#title_param {
  color: #647491;
  font-weight: 600;
  line-height: 1.2;
  padding-left: 10px;
  background-color: #f6fbfc;
  height: 50px;
  padding-top: 10px;
  font-size: 20px;
  text-align: left;
  border-bottom: 1px solid #e0e3e9;
}
#content_param {
  padding: 10px;
}
.info-config-data tr td {
  border-bottom: 1px solid #002055;
}
.content-array {
  margin: 0 auto;
  vertical-align: middle;
  width: 80%;
  tr td {
    padding: 10px;
    //min-width: 250px;
  }
}
.app-modal-upload-body {
  $dark-blue: #002055;
  $pale-blue: #e6f3f6;
  $blue-lynch: #647491;
  $blue-midnight: #00205b;

  padding: 20px 70px;
  &_title {
    label th {
      color: $dark-blue;
      font-size: 14px;
      font-weight: 400;
    }
    div {
      font-weight: 600;
    }
  }
  &_content {
    span,
    label {
      font-size: 14px;
      font-weight: 400;
      color: $dark-blue;
      .inputStyle {
        color: $blue-lynch;

        &:hover {
          color: #fff;
        }
      }
    }
    label {
      font-weight: 400;
      line-height: 2.5;
      color: $blue-lynch;
    }
    .btn-status {
      display: block;
      font-size: 12px;
      color: #647491;
    }
    .columns {
      margin: 0;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .column {
        padding: 0;
      }
    }
    .buttons-wrapper {
      margin-top: 50px;
      .cancel-clean {
        float: right;
      }
    }
  }
}
</style>
