import { AuthService } from '../../utils/authService'
import ApiService from '../../utils/apiService'

const state = {
  email: '',
  user_name: '',
  organization:'',
  idToken: '',
  token: '',
  role: '',
  displayRoles: {
    'Internal-adm': 'AH Admin',
    'Internal-usr': 'AH User',
    'MaintenanceCenter-adm': 'Maintenance Center Admin',
    'MaintenanceCenter-usr': 'Maintenance Center User',
    'Customer-adm': 'Admin',
    'Customer-usr': 'User'
  }
}

const actions = {
  loadRole ({ commit }) {
    return new Promise( async function (resolve, reject) {
      AuthService.getRole().then((role) => {
        if (role == null) {
          alert('No DFM roles were found for your account')
          AuthService.signOut()
          resolve(true)
        } else {
          commit('setRole', role.toString())
          resolve(true)
        }
      })
    })
  },
  loadToken ({ commit }) {
    return new Promise( async function (resolve, reject) {
      AuthService.getAccessToken().then((token) => {
        commit('setToken', token.toString())
        //console.log('setToken', token.toString())
        resolve(true)
      })
    })
  },
  loadEmail ({ commit }) {
    return new Promise( async function (resolve, reject) {
      AuthService.getEmail().then((email) => {
        commit('setEmail', email.toString())
        resolve(true)
      })
    })
  },
  loadOrganization ({ commit }) {
    if (state.organization == '') {
      return new Promise( async function (resolve, reject) {
        ApiService
          .get('/organization').then(response => {
            if(response.data == null || response.data == "None"){
              commit('setOrganization', "Airbus Helicopters")
            } else {
              commit('setOrganization', response.data.toString())
            }
            resolve(true)
          })
      })
    }
  },
  loadUserName ({ commit }) {
    return new Promise( async function (resolve, reject) {
      AuthService.getUserName().then((name) => {
        var res = name.split('_')[1]
        if (res == null) {
          res = name
        }
        commit('setUsername', res.toString())
        resolve(true)
      })
    })
  },
  loadTokenId ({ commit }, idToken) {
    return new Promise( async function (resolve, reject) {
      AuthService.getIdToken().then((idtoken) => {
        commit('setTokenId', idtoken.toString())
        resolve(true)
      })
    })
  },
  loadRenewedTokenId ({ commit }, idToken) {
    return new Promise( async function (resolve, reject) {
      AuthService.getIdToken().then((idtoken) => {
        commit('setTokenId', idtoken.toString())
        //console.log('setTokenId', idtoken.toString())
        resolve(true)
      })
    })
  },
  logOut ({commit}) {
    return new Promise( async function (resolve, reject) {
      commit('setEmail', '')
      commit('setOrganization', '')
      commit('setUsername', '')
      commit('setTokenId', '')
      commit('setToken', '')
      commit('setRole', '')
      resolve(true)
    })
  },
}

const getters = {
  isAdmin: state => {
    return state.role === 'Internal-adm' || state.role === 'MaintenanceCenter-adm' || state.role === 'Customer-adm'
  },
  isAHAdmin: state => {
    return state.role === 'Internal-adm' 
  },
  canFilter: state => {
    return state.role !== 'Customer-adm' && state.role !== 'Customer-usr'
  }
}

const mutations = {
  setTokenId (state, newToken) {
    state.idToken = newToken
  },
  setRole (state, newRole) {
    state.role = newRole
  },
  setToken (state, newToken) {
    state.token = newToken
  },
  setEmail (state, newEmail) {
    state.email = newEmail
  },
  setOrganization (state, new_organization) {
    state.organization = new_organization
  },
  setUsername (state, newUserName) {
    state.user_name = newUserName
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}