export const ROUTERS = {
  dashboard: {
    name: 'dashboard',
    meta: {
      langLabel: 'dashboard',
      title: 'Dashboard'
    }
  },
  profile: {
    name: 'profile',
    meta: {
      langLabel: 'my_profile',
      title: 'My profile'
    }
  },
  configuration: {
    name: 'configuration',
    meta: {
      langLabel: 'configuration',
      title: 'Configuration'
    }
  },
  transfer: {
    name: 'transfer',
    meta: {
      langLabel: 'transfer',
      title: 'Transfer status'
    }
  },
  management: {
    name: 'management',
    meta: {
      langLabel: 'management',
      title: 'Event management'
    }
  },

  alert: {
    name: 'alert',
    meta: {
      langLabel: 'alert',
      title: 'Alert configuration'
    }
  },
  login: {
    name: 'login',
    meta: {
      // this item is only accessible if not logged in
      auth: false
    }
  }
}
