<template>
  <div v-if="display">
    <div v-for="text in texts" @click="Display()" class="description-text" v-bind:key="text">{{ text }}</div>
  </div>
  <div v-else>
    <div class="description-icon"><span @click="Display()" class="icon-info"></span></div>
  </div>
</template>

<script>
export default {
  name: 'Description',
  props: {
    texts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      display: false
    }
  },
  methods: {
    Display () {
      this.display = !this.display
    }
  }
}
</script>
<style lang="scss">
  .description-icon {
    margin-top: 1%;
  }
  .icon-info {
    cursor: pointer;
  }
  .description-text {
    margin-bottom: 1%;
    padding: 1%;
    color: #002055;
    background-color: #f6fbfc;
    box-shadow: 0 2px 4px #002055;
    margin-top: 2%;
  }
</style>
