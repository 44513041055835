<template>

  <section :style ="{background: linearGradientStyle}" class="columns action-bar light">
    <div class="column">
      <div v-if="!activeSelection" class="checkbox-holder" style="padding-left: 12px;">
        <input
          id="select-all-HC"
          type="checkbox"
          name="select-all-HC"
          v-model="selectedAllFleet"
        >
        <label v-if="selectedAircraftCounter === 0" for="select-all-HC">{{ $t('global.button_select_hc') }}</label>
        <label v-else for="select-all-HC" />
        <span v-if="selectedAircraftCounter > 0" class="text-holder">
          <span class="main-counter" :class="{round: this.selectedAircraftCounter < 10}">{{ this.selectedAircraftCounter }}</span>
          <span class="text-bold">HC</span>
        <span>{{ $t('global.selected') }}</span>
        <span class="text-holder">
          <span>{{ $t('global.all') }}</span>
          <span class="text-bold">{{ this.selectedAircraftCounter }}</span>
          <span>{{ $t('global.accessible_hc') }}</span>
          <span class="clear-button" @click="deSelectAll">{{ $t('global.clear_selection') }}</span>
        </span>
      </span>
      </div>
      <div v-else class="checkbox-holder" style="padding-left: 12px;">
        <input
          id="de-select-all-HC"
          type="checkbox"
          name="de-select-all-HC"
        >
        <label @click="deSelectAll" class="deselectSelected" or="de-select-all-HC" />
        <span class="text-holder">
          <span class="main-counter" :class="{round: this.selectedAircraftCounter < 10}">{{ this.selectedAircraftCounter }}</span>
          <span class="text-bold">HC</span>
        <span>{{ $t('global.selected') }}</span>
      </span>
      </div>
    </div>

    <div class="column is-3">
      <div class="sorting-holder is-pulled-right">
        <span>{{ $t('global.sort') }}</span>
        <a class="btn-view" @click="changeFleetLayout(!fleetTableView)">
          <span :class="[fleetTableView === true ? 'icon-format-list-bulleted' : 'icon-view-dashboard']"></span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('appStore', ['fleetTableView']),
    ...mapGetters('fleetStore', ['areAllFleetAdded', 'isAnyActiveSelection', 'selectedHeliCounter']),
    selectedAllFleet: {
      get () {
        return this.areAllFleetAdded
      },
      set (newValue) {
        return newValue ? this.addAllMachinesFromAllFleet() : this.removeAll()
      }
    },
    activeSelection () {
      if (!this.areAllFleetAdded) {
        return this.isAnyActiveSelection
      }
      return false
    },
    selectedAircraftCounter () {
      return this.selectedHeliCounter
    },
  },
  methods: {
    ...mapActions('fleetStore', [
      'addAllMachinesFromAllFleet',
      'removeAll'
    ]),
    ...mapActions('appStore', ['changeFleetLayout']),
    deSelectAll () {
      this.removeAll()
    },
    linearGradientStyle(){
      const startColor = process.env.VUE_APP_BACKGROUND_1
      const endColor = process.env.VUE_APP_BACKGROUND_2
      return `linear-gradient(to left, ${startColor}, ${endColor})`
    },
  }
}
</script>

<style lang="scss" scoped>
.checkbox-holder label::after {
  line-height: 10px;
}
</style>