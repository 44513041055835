const STORE_FLEET = 'STORE_FLEET'
const STORE_FLEET_HASH_MAP = 'STORE_FLEET_HASH_MAP'
const FLEET_QUERY = 'FLEET_QUERY'
const STORE_SELECTED = 'STORE_SELECTED'
const ADD_UNIQUE = 'ADD_UNIQUE'
const REMOVE_PROVIDED = 'REMOVE_PROVIDED'
const ADD_MACHINE = 'ADD_MACHINE'
const REMOVE_MACHINE = 'REMOVE_MACHINE'

const state = {
  fleetQuery: '',
  fleetList: [],
  fleetHashMap: {},
  selected: [],
  selectedAll: false,
  deselectActive: false
}

const actions = {
  setFetchedFleet({ commit, state }, fleetArr) {
    // Create a deep copy of the fleetList to avoid mutating the original state directly
    const updatedFleetList = [...state.fleetList];
  
    fleetArr.forEach(newFleet => {
      const existingFleetIndex = updatedFleetList.findIndex(existingFleet => existingFleet.name === newFleet.name);
      
      if (existingFleetIndex !== -1) {
        // Check if we have aircrafts in both the existing and new fleets
        if (updatedFleetList[existingFleetIndex].aircrafts && newFleet.aircrafts) {
          newFleet.aircrafts.forEach(newAircraft => {
            const existingAircraftIndex = updatedFleetList[existingFleetIndex].aircrafts.findIndex(existingAircraft =>
              existingAircraft.serial === newAircraft.serial && existingAircraft.type === newAircraft.type);
  
            // Check if we found the aircraft and it has a valid session object
            if (existingAircraftIndex !== -1) {
              const existingAircraft = updatedFleetList[existingFleetIndex].aircrafts[existingAircraftIndex];
              
              // Ensure the session object exists and is valid
              if (existingAircraft.session && Object.keys(existingAircraft.session).length > 0) {
                newAircraft.session = existingAircraft.session;
              }
            }
          });
        }
      }
    });
    commit(STORE_FLEET, [...fleetArr]);
    commit(STORE_FLEET_HASH_MAP, [...fleetArr]);
  },
  addMachine ({ commit }, machine) {
    commit(ADD_MACHINE, machine)
  },
  removeMachine ({ commit }, machine) {
    commit(REMOVE_MACHINE, machine)
  },
  addAllMachinesFromAllFleet ({ commit, state }) {
    const allMachines = state.fleetList.reduce((all, currentFleet) => {
      const machines = currentFleet.aircrafts.map(air => (air.type + '/' + air.serial))

      return all.concat(machines)
    }, [])
    commit(STORE_SELECTED, allMachines)
  },
  removeAll ({ commit }) {
    commit(STORE_SELECTED, [])
  },
  setFleetQuery ({ commit }, query) {
    commit(FLEET_QUERY, query)
  },
  addAllMachinesFromFleet ({ commit, state }, registration) {
    // transform the machines to a simple array of id's
    commit(ADD_UNIQUE, state.fleetHashMap[registration])
  },
  removeAllMachinesFromFleet ({ commit, state }, registration) {
    commit(REMOVE_PROVIDED, state.fleetHashMap[registration])
  }
}

const mutations = {

  [STORE_FLEET] (state, fleet) {
    state.fleetList = fleet
  },
  [STORE_FLEET_HASH_MAP](state, fleet) {
    state.fleetHashMap = fleet.reduce((all, currentFleet) => {
      all[currentFleet.name] = currentFleet.aircrafts.map(m => (m.type + '/' + m.serial))
      return all
    }, {})
  },
  [FLEET_QUERY] (state, query) {
    state.fleetQuery = query
  },
  [STORE_SELECTED] (state, items) {
    state.selected = items
  },
  [ADD_UNIQUE] (state, items) {
    state.selected = [...new Set([...state.selected, ...items])]
  },
  [ADD_MACHINE] (state, machine) {
    state.selected = [...state.selected, (machine.type + '/' + machine.serial)]
  },
  [REMOVE_MACHINE] (state, machine) {
    let removedArr = state.selected.filter(m => m !== (machine.type + '/' + machine.serial))
    state.selected = removedArr
  },
  [REMOVE_PROVIDED] (state, items) {
    state.selected = state.selected.filter(m => !items.includes(m))
  }
}

const getters = {
  // We could also transform them to an object (hashmap) for easier searching
  getfleet: (state, getters) => fleetRegistration => {
    return state.fleetList.filter(fl => fl.registration === fleetRegistration)
  },
  isWholeFleetAdded: state => fleetRegistration => {
    return state.fleetHashMap[fleetRegistration].every(fl => state.selected.includes(fl))
  },
  areAllFleetAdded: state => {
    if (state.fleetList.length === 0) {
      return false
    }
    return (
      state.selected.length ===
        state.fleetList.reduce((all, current) => {
          return all + current.aircrafts.length
        }, 0)
    )
  },
  getSelectedDetailed: state => {
    let selectedDetiledArr = []
    state.fleetList.map(f => {
      f.aircrafts.map(a => {
        if (state.selected.includes((a.type + '/' + a.serial))) { return selectedDetiledArr.push(a) }
      })
    })
    return selectedDetiledArr
  },
  isAnyActiveSelection () {
    return state.selected.length > 0
  },
  selectedHeliCounter () {
    return state.selected.length
  },
  getHCDetailed: state => registration => {
    let HCObject
    state.fleetList.forEach(f => {
      f.aircrafts.forEach(a => {
        if (a.serial === registration.serial && a.type === registration.type) {
          HCObject = a
        }
      })
    })
    return HCObject
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}