<!-- Z2I81GIRE-162 -->
<template>
  <div>
    <PageLoading v-if="requsterProcesing" />
    <div class="modal-retrieve" :class="{'hide-content-on-loading': this.requsterProcesing}">
      <!-- List registration -->
      <div class="modal-retrieve__registrations-list">
        <label v-if="getSelectedDetailed.length > 1 || helicopter">{{ $t('banHCModal.sub_title_1') }}</label>
        <label v-if="getSelectedDetailed.length <= 1">{{ $t('banHCModal.sub_title_2') }}</label>
      </div>
      <div class="is-gapless retrive-multi__registrations">
        <div v-if="helicopter" :key="helicopter.registration">
          {{cleanData(helicopter.type)}}/{{helicopter.serial}} : {{helicopter.registration}}
        </div>
        <div v-else>
          <div v-for="heli in getSelectedDetailed" :key="heli.registration" >
            {{cleanData(heli.type)}}/{{heli.serial}} : {{heli.registration}}
          </div>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="columns is-centered modal-retrieve__buttons-wrapper">
      <a @click="closeModal" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
      <a class="button btn validationBtn" @click="banHC()">{{ $t('global.button_ban_hc') }}</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '../../mixins/utils'
import PageLoading from '../../views/PageLoading'

export default {
  components: {
    PageLoading
  },
  name: 'ModalBanHC',
  props: {
    helicopter: {
      type: Object,
      required: false
    }
  },
  mixins: [utils],
  data () {
    return {
      requsterProcesing: false,
      modal: false
    }
  },
  computed: {
    ...mapGetters('fleetStore', ['getSelectedDetailed'])
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    banHC () {
      this.requsterProcesing = true
      let formData = new FormData()
      if(this.helicopter){
        formData.append('registration', this.helicopter.registration)
        formData.append('serial', this.helicopter.serial)
        formData.append('type', this.helicopter.type)
      }else{
        let selectedHelicopters = this.getSelectedDetailed.map(h => {
          return {
            registration: h.registration,
            serial: h.serial,
            type: h.type
          }
        })
        formData.append('selected_helicopters', JSON.stringify(selectedHelicopters))
      }



      this.$requester
        .post('/ban_helicopters', formData, {
          responseType: 'blob'
        })
        .then(response => {
          this.$router.go()
        })
        .catch(error => {
          if (error.response) {            
            let errMsg = this.$t('errorMsg.err')
            this.$emit('responseError', `${errMsg}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
.retrive-multi__registrations {
  margin: 20px 0 !important;
  div {
    font-size: 14px;
  }
}
</style>
