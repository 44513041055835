<template>
  <div class="alert__card shadow">
    <div class="alert__header">
      <table  aria-label="Signal Status Table" style="width:100%; vertical-align:middle">
        <thead>
            <tr>
                <th style="width:30%">{{ $t('alert.group_name') }}</th>
                <th style="width:30%">{{ $t('alert.duration') }}</th>
                <th style="width:20%">{{ $t('alert.actions') }}</th>
                <th style="width:20%">{{ $t('alert.visibility') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td id="row" style="width:30%">
                    <div class="alert__title">{{group.group_name}}</div>
                </td>
                <td id="row" style="width:30%;">
                    <table  aria-label="Signal Status Table" style="margin:auto">
                        <thead>
                            <tr>
                                <th id="col">{{ $t('alert.days') }}</th>
                                <th id="col">{{ $t('alert.hours') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="row" style="padding:0px 3px;">{{group.days + ' ' + $t('alert.day')}}</td>
                                <td id="row" style="padding:0px 3px;">{{group.hours + ' ' + $t('alert.hour')}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td id="row" style="width:20%;">
                    <table  aria-label="Signal Status Table" style="margin:auto;">
                        <thead>
                            <tr>
                                <th id="col">{{ $t('alert.add') }}</th>
                                <th id="col">{{ $t('alert.edit') }}</th>
                                <th id="col">{{ $t('alert.delete') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="row">
                                    <v-tooltip slot="append" bottom color="rgb(177, 216, 242)" content-class="blue-tooltip">
                                        <svg slot="activator" class="alert__button" @click="openAddAlertModal()" fill="#005587" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                        <span>{{ $t('alert.tooltip_add_alert') }}</span>
                                    </v-tooltip>
                                </td>
                                <td id="row">
                                    <v-tooltip slot="append" bottom color="rgb(177, 216, 242)" content-class="blue-tooltip">
                                        <svg slot="activator" class="alert__button" @click="openEditAlertGroupModal()" fill="#005587" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                        <span>{{ $t('alert.tooltip_edit_alert_group') }}</span>
                                    </v-tooltip>
                                </td>
                                <td id="row">
                                    <v-tooltip slot="append" bottom color="rgb(227, 0, 43)">
                                        <svg slot="activator" class="alert__button" @click="openDeleteAlertGroupModal()" fill="#005587" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                        <span>{{ $t('alert.tooltip_delete_alert_group') }}</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td id="row" style="width:20%; float:right">
                    <svg v-if="hide" @click="hide = !hide" class="alert__button" fill="#005587" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                    </svg>
                    <svg v-if="!hide" @click="hide = !hide" class="alert__button" fill="#005587" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </td>
            </tr>
        </tbody>
    </table>

    </div>
    <div class="alert__content" v-if="!hide">
      <table  aria-label="Signal Status Table" class="content-array">
          <tr>
            <th v-for="alert in group.alerts" :key="alert.id" class="alert__alert">{{ alert }}</th>
          </tr>
          <tr>
            <td v-for="alert in group.alerts" :key="alert.id" class="alert__alert">
              <AlertCard
                :alert="alert"
                @reload="reload"
              />
            </td>
          </tr>
        </table>

    </div>
    <modal :modalName="deleteAlertGroupModal.name" width="430px">
      <ModalConfirm
        :message="deleteAlertGroupModal.message"
        :cancel=true
        @modalConfirmed="deleteAlertGroup()"
      />
    </modal>
    <modal :title="editAlertGroupModal.title" :modalName="editAlertGroupModal.name" width="430px">
      <ModalEditAlertGroup
        v-bind:group="group"
        @reload="reload"
      />
    </modal>
    <modal :title="addAlertModal.title" :modalName="addAlertModal.name" width="430px">
      <ModalAddAlert
        v-bind:group="group"
        @reload="reload"
       />
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Modal from '../components/modals/Modal'
import ModalConfirm from '../components/modals/ModalConfirm'
import ModalEditAlertGroup from '../components/modals/ModalEditAlertGroup'
import ModalAddAlert from '../components/modals/ModalAddAlert'
import AlertCard from './AlertCard'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  directives: {
    onClickaway: onClickaway
  },
  components: {
    Modal,
    ModalConfirm,
    ModalEditAlertGroup,
    ModalAddAlert,
    AlertCard
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editAlertGroupModal: {
        name: 'editAlertGroupModal' + this.group.id,
        title: 'alert.editAlertGroupModal_title'
      },
      addAlertModal: {
        name: 'addAlertModal' + this.group.id,
        title: 'alert.addAlertModal_title'
      },
      deleteAlertGroupModal: {
        message: this.$t('alert.delete_alert_group_message'),
        name: 'deleteAlertGroupModal' + this.group.id
      },
      hide: true,
      selectedAlert: {}
    }
  },
  computed: {
    ...mapState(['userStore'])
  },

  methods: {
    ...mapActions('appStore', ['openModal', 'closeModal']),
    reload () {
      this.$emit('reload')
    },
    deleteAlertGroup () {
      const formData = new FormData()
      formData.append('group', JSON.stringify(this.group))
      this.$requester
        .post('/delete_alert_group', formData)
        .then(response => {
          this.$emit('reload')
          this.closeModal()
        })
        .catch(error => {
          console.log(error)
        })
    },
    openDeleteAlertGroupModal (group) {
      this.openModal(this.deleteAlertGroupModal.name)
    },
    openEditAlertGroupModal () {
      this.openModal(this.editAlertGroupModal.name)
    },
    openAddAlertModal () {
      this.openModal(this.addAlertModal.name)
    }
  }
}
</script>
