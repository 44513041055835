<template>
  <div class="event-data">
    <div class="columns">
      <div class="column is-2">
        <div class="icon-holder">
          <div class="icon-heli icon"></div>
        </div>
      </div>
      <div v-if="event.event_type === 'disconnected'" class="column is-4">
        <div class="type">
          <p>{{ $t('helicard.no_data') }}</p>
          <p>{{ convertEventMessage(event.event_message) }}</p>
        </div>
      </div>
      <div v-else-if="event.event_type === 'health'" class="column is-4">
        <div class="type">
          <p>{{ $t('helicard.wacs_error') }}</p>
          <p>{{ convertEventMessage(event.event_message) }}</p>
        </div>
      </div>
      <!--
      <div v-else-if="event.event_type === 'error_download'" class="column is-4">
        <div class="type">
          <p>{{ $t('helicard.download_error') }}</p>
          <p>{{ convertEventMessage(event.event_message) }}</p>
        </div>
      </div>
      -->
      <div class="column is-1">
        <div class="event-date">
          <span class="date">{{this.$moment(event.event_date + ' UTC').format('D MMM YYYY')}}</span>
          <span class="hour">{{this.$moment(event.event_date + ' UTC').format('HH:mm a') + ' (local)'}}</span>
        </div>
      </div>
      <div class="column is-2">
        <v-tooltip v-if="!event.acknowledged" slot="append" bottom class="acknowledged-by">
          <span slot="activator">
            <a
              @click="openModal(confirmAcknowledgeModal.name)"
              href="#"
            >{{ $t('helicard.acknowledge') }}</a>
          </span>
          <span>{{ $t('acknowledge.button_tooltip') }}</span>
        </v-tooltip>
        <span v-else class="acknowledged-by">
          <span>{{event.acknowledged_by}}</span>
        </span>
      </div>
      <div class="column is-1">
        <div v-if="event.acknowledged_date" class="acknowledge-date">
          <span class="date">{{this.$moment(event.acknowledged_date + ' UTC').format('D MMM YYYY')}}</span>
          <span class="hour">{{this.$moment(event.acknowledged_date + ' UTC').format('HH:mm a') + ' (local)'}}</span>
        </div>
        <div v-else class="acknowledge-date"></div>
      </div>
      <div class="column is-1">
        <div v-if="event.acknowledged" class="trash-holder">
          <v-tooltip slot="append" bottom color="">
            <span class="trash icon-Trash-FInal" @click="openModal(confirmRemoveEventModal.name)" slot="activator"></span>
            <span>{{ $t('helicard.remove_message') }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <modal :modalName="confirmAcknowledgeModal.name" width="430px">
      <ModalConfirm
        :cancel="true"
        :message="confirmAcknowledgeModal.message"
        @modalConfirmed="setAcknowledgeEvent()"
      />
    </modal>
    <modal :modalName="confirmRemoveEventModal.name" width="430px">
      <ModalConfirm
        :cancel="true"
        :message="confirmRemoveEventModal.message"
        @modalConfirmed="emitDeleteEvent()"
      />
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '../modals/Modal'
import ModalConfirm from '../modals/ModalConfirm'
import utils from '../../mixins/utils'

export default {
  name: 'HeliEvent',
  mixins: [utils],
  components: {
    Modal,
    ModalConfirm
  },
  props: {
    event: {
      type: Object
    },
    serial: {
      type: String
    },
    type: {
      type: String
    },
    registration: {
      type: String
    }
  },
  data () {
    return {
      confirmAcknowledgeModal: {
        name: `confirmAcknowledgeModal${this.event.id}`,
        message: `${this.$t('acknowledge.confirm_msg')}
        ${this.cleanData(this.type)}/${this.serial} : ${this.registration}`
      },
      // Modification due to the referenced US : Z2I81GIRE-232
      confirmRemoveEventModal: {
        name: `confirmRemoveEventModal${this.event.id}`,
        message: `${this.$t('acknowledge.confirm_remove_msg')}`
      }
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal', 'openModal']),
    setAcknowledgeEvent () {
      let formData = new FormData()

      let listEvents = []
      let heli = {
        serial: this.serial,
        type: this.type
      }

      listEvents.push({ 'event': this.event, 'hc': heli })
      formData.append('events_to_acknowledge', JSON.stringify(listEvents))
      formData.append('username', this.$store.state.userStore.user_name)

      this.$requester
        .post('/acknowledge', formData)
        .then(response => {
          this.closeModal()
          this.showSuccess()
        })
        .catch(error => {
          console.log(error)
        })
    },
    cleanData (value) {
      return value.replace(new RegExp('_', 'g'), ' ')
    },
    showSuccess () {
      this.$emit('showSuccessMsg')
    },
    // Modification due to the referenced US : Z2I81GIRE-232
    emitDeleteEvent () {
      let formData = new FormData()
      let listEvents = []
      let heli = {
        serial: this.serial,
        type: this.type
      }
      listEvents.push({ 'event': this.event, 'hc': heli })
      formData.append('events_to_delete', JSON.stringify(listEvents))

      this.$requester
        .post('/acknowledge/delete', formData)
        .then(response => {
          this.closeModal()
          this.showSuccess()
        })
        .catch(error => {
          console.log(error)
        })
    },
    convertEventMessage (message) {
      if (message.split(' ').length === 4) {
        return this.$t('helicard.connectivity_event_message_never_connected')
      } else if (message.split(' ').length === 9) {
        var msg = this.$t('helicard.connectivity_event_message_not_connected_from')
        var day = message.split(' ')[4]
        var hour = message.split(' ')[7]
        msg = msg.replace(' x ', ' ' + day + ' ')
        msg = msg.replace(' y ', ' ' + hour + ' ')
        return msg
      } else if (message.split(' ').length === 3) {
        return this.$t('helicard.wacs_error_message')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.event-data {
  display: flex;
  box-shadow: 0 2px 4px #c3c3c3;
  margin-bottom: 15px;
  .icon-holder {
    border: 1px solid #e0e3e9;
    padding: 11px;
    width: 90%;
    background-color: #f6fbfc;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-heli {
      font-size: 42px;
    }
    .number-hc {
      font-size: 14px;
      margin-left: 20px;
      color: #00205b;
      font-weight: 500;
    }
  }
  .columns {
    width: 100%;
    margin: 0;
    .column {
      padding: 0;
      display: flex;
      // justify-content: center;
      .type p:nth-child(1) {
        font-weight: 500;
      }
      .acknowledged-by {
        width: 100%;
        display: flex;
        justify-content: center;
        a {
          padding: 3px 10px;
          border: 1px solid #647491;
          border-radius: 5px;
          color: #647491;
          transition: all 0.3s ease;
          &:hover {
            box-shadow: -1px 2px 10px -4px;
          }
        }
      }
    }
    .trash{
      cursor: pointer;
    }
    .trash-holder {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        height: 20px;
      }
    }
    p,
    span {
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #00205b;
    }
    .acknowledge-date,
    .event-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .date,
      .hour {
        width: 100%;
        text-align-last: left;
      }
      .date {
        color: #0082d5;
        font-weight: 300;
      }
    }
  }
}
</style>
