<template>
  <div class="page-loading">
  <h1 v-if="message">{{ message }}</h1>
  <h1 v-else>{{ $t('global.loading_text') }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.page-loading {
  width: 200px;
  height: 200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  h1 {
    animation: pulse 3s infinite;
    text-align: center;
    white-space: pre-wrap; /* Allow line breaks */
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.05;
  }
  100% {
    opacity: 1;
  }
}
</style>
